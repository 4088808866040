import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Card, Checkbox, Col, Divider, Image, List, message, Radio, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";
import OPISvg from 'src/assets/img/add_order/OPI_logo.svg';
import prebookingSvg from 'src/assets/img/add_order/prebooking.svg';

let store = require('src/store');
let config = require('commonProject/config/config');
let api = require('src/api');

function getSupportService() {
    api.order.get_support_service_type_list().then(res => {
        store.add_order.chooseSupportService = res.data;
    })
}

// create observer
let ChoosePrebooking = observer(function () {
    let history = useHistory();
    let formData = store.persist.add_order.formData;

    useEffect(() => {
        // getSupportService();
    }, [])

    return (
        <Col>
            <p>step 4 of 4</p>
            <p>Pre-booking, or now</p>
            <Divider/>
            <p>{`Language Chosen:${formData.chooseLang.language_name}-${formData.support_service_type.type_name}`}</p>
            <p/>
            <div>
                <Card>
                    <Row align='middle' onClick={history.push('/add_order/prebooking_time')}>
                        <Image src={prebookingSvg}/>
                        <Col style={{'margin-left': '10px'}}>
                            <p>Pre-booking</p>
                            <p>Pre-booking is encouraged</p>
                            <p>enjoy 1% - 3% discount</p>
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Row align='middle'>
                        <Image src={OPISvg}/>
                        <Col style={{'margin-left': '10px'}}>
                            <p>I need service now</p>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Col>
    );
})

export default ChoosePrebooking;