import {message} from "antd";

let store = require('src/store');
let api = require('src/api');
let commonUtils = require('commonProject/utils/Common');

class LoginHelper {
    static async login(params, history) {
        let res = await api.interpreter.login(params);
        if (res.code == 0) {
            this.loginAfter(res, history);
        } else message.error(res.msg);
    }

    static async loginAfter(res, history) {
        // store.persist.userInfo = res.data.user;
        store.persist.token = res.data.token;
        await api.interpreter.get_interpreter_info();
        message.success('login success');
        return history.push('/home_login');
        // if (!store.persist.is_never_show_welcome) return history.push('/welcome');
        // history.push('/home_login');
    }

    static async registerAfter(res,history){
        store.persist.userInfo = res.data.user;
        store.persist.token = res.data.token;
        // await api.interpreter.get_interpreter_info();
        message.success('register success');
        return history.push('/register_add_info');
    }

    static async logout(history) {
        let store = require('src/store');
        let res = await api.interpreter.logout();
        store.persist.token = '';
        await commonUtils.timeSleep(500);
        commonUtils.historyPushRefresh(history, '/');
    }
}

module.exports = LoginHelper;
