class OrderHelper {
    static getStatusName(statusCode) {
        let statusName = '';
        //订单状态 0：已失效  1：预约中  2：已预约
        // 3：口译订单执行中  4口译师开始  5用户开始   6口译师结束  7用户结束
        // 8：订单执行完成
        switch (statusCode) {
            case 0:
                statusName = 'cancel or invalid'
                break;
            case 2:
                statusName = 'coming soon'
                break;
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                statusName = 'active'
                break;
            case 8:
                statusName = 'order finish'
                break;
            case 9:
                statusName = 'order pause'
                break;
            default:
                statusName = 'error';
        }
        return statusName;
    }

    //获取聊天时候的不同状态的内容
    static getStatusDesc(statusCode) {
        let statusName = '';
        //订单状态 0：已失效  1：预约中  2：已预约
        // 3：口译订单执行中  4口译师开始  5用户开始   6口译师结束  7用户结束
        // 8：订单执行完成
        switch (statusCode) {
            case 0:
                statusName = 'cancel or invalid'
                break;
            case 2:
                statusName = 'coming soon'
                break;
            case 3:
                break;
            case 4:
                statusName = 'Interpreter confirm started. '
                break;
            case 5:
                statusName = 'User confirm started.'
                break;
            case 6:
                statusName = 'Interpreter confirm ended.'
                break;
            case 7:
                statusName = 'User confirm ended'
                break;
            case 8:
                statusName = 'order finish'
                break;
            case 9:
                statusName = 'order pause'
                break;
            default:
                statusName = 'error';
        }
        return statusName;
    }
}

module.exports = OrderHelper;
