import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useParams} from "react-router";
import {Button, Col, Input, Row, message, Divider} from "antd";
import {Link, useHistory} from "react-router-dom";
import css from './login.module.less';
import CountDown from "ant-design-pro/lib/CountDown";

let store = require('src/store');
let data = store.login;
let api = require('src/api');
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');
let loginHelper = require('src/utils/helper/login_helper');


/**
 * 登录
 * @param type 登录方式  email邮箱登录  phone 手机号登录
 * @returns {MessageType}
 */
function submitLogin(type = 'email') {
    let params;
    if (type == 'email') {
        data.user_name = data.email;
        params = {
            user_name: data.user_name,
            email_code: data.email_code,
        };
    } else if (type == 'phone') {
        params = {
            phone_num: data.phone_num,
            phone_code: data.phone_code,
        };
    }
    loginHelper.login(params, history);
}

//发送手机验证
async function verify_phone_send_code(is_voice = false) {
    let phone_num = data.phone_num;
    if (commonUtils.isEmpty(phone_num)) return message.error('phone num is empty');
    let res = await api.interpreter.verify_phone_send_code({phone_num: phone_num, is_verify_new: false, is_voice});
    if (res.code != 0) return;
    message.success('send code success');
    data.phone_code_time = new Date().getTime() + commonUtils.config.valid_code_time;
    data.is_show_login_view = true;
    data.login_type = 'phone';
}

//发送手机验证
async function verify_email_send_code() {
    let email = data.email;
    if (commonUtils.isEmpty(email)) return message.error('email is empty');
    let res = await api.interpreter.verify_email_send_code({email: email, is_verify_new: false});
    if (res.code != 0) return;
    message.success('send code success');
    data.phone_code_time = new Date().getTime() + commonUtils.config.valid_code_time;
    data.is_show_login_view = true;
    data.login_type = 'email';
}


async function initData() {

}

// create observer
let Login = observer(function () {
    let urlParams = useParams();
    history = useHistory();

    useEffect(async () => {
        await initData();
    }, []);

    return (
        !data.is_show_login_view ? (
            <Col className={css.containBox}>
                <p className={css.headTitleBox + ' wineRed'}>Interpter Log In</p>
                <div className={css.loginBox} style={{textAlign: 'center'}}>
                    <span className={css.desc}>using email:</span><span className={css.hint + ' hintGray'}>(We will email you a pass code).</span>
                    <p/>
                    <Input placeholder='Your Email'
                           className={css.inputEmail}
                           defaultValue={data.email} onChange={(e) => {
                        data.email = e.target.value
                    }}/>
                    <Col align={'middle'}>
                        <Button className={css.btn} onClick={verify_email_send_code}>Send Code</Button>
                    </Col>
                </div>

                {/*<div className={css.loginBox}>*/}
                {/*    <span className={css.desc}>using cell phone:</span><span className={css.hint + ' hintGray'}>(We will text you a code).</span>*/}
                {/*    <Input placeholder='US 10-digit cell phone number' defaultValue={data.phone_num} onChange={(e) => {*/}
                {/*        data.phone_num = e.target.value*/}
                {/*    }}/>*/}

                {/*    <Col align={'middle'}>*/}
                {/*        <Button className={css.btn} onClick={() => {*/}
                {/*            verify_phone_send_code(false)*/}
                {/*        }}>Send Code</Button>*/}
                {/*    </Col>*/}
                {/*</div>*/}

                {/*<div className={css.loginBox}>*/}
                {/*    <span className={css.desc}>using landline:</span><span className={css.hint + ' hintGray'}>(We will call you for a code).</span>*/}
                {/*    <Input placeholder='US 10-digit landline number' defaultValue={data.phone_num} onChange={(e) => {*/}
                {/*        data.phone_num = e.target.value*/}
                {/*    }}/>*/}
                {/*    <Col align={'middle'}>*/}
                {/*        <Button className={css.btn} onClick={() => {*/}
                {/*            verify_phone_send_code(true)*/}
                {/*        }}>Send Code</Button>*/}
                {/*    </Col>*/}
                {/*</div>*/}
            </Col>
        ) : (
            <Col className={css.containBox}>
                <p className={css.headTitleBox + ' wineRed'}>Interpter Log In</p>
                <Col className={css.loginBox} align={'middle'}>
                    <div>
                        {data.login_type == 'phone' ? (
                            <div>
                                <span className={css.desc}>using cell phone:</span><span
                                className={css.hint + 'hintGray'}>(We will text you a code).</span>
                                <p style={{display: 'none'}}>{data.phone_num}</p>
                                <Input placeholder='US 10-digit cell phone number' defaultValue={data.phone_num}
                                       onChange={(e) => {
                                           data.phone_num = e.target.value
                                       }}/>
                            </div>
                        ) : (
                            <div style={{textAlign: 'center'}}>
                                <span className={css.desc}>using email:</span><span className={'hintGray'}>(We will email you a pass code).</span>
                                <p/>
                                <Input placeholder='Your Email'
                                       className={css.inputEmail}
                                       defaultValue={data.email} onChange={(e) => {
                                    data.email = e.target.value
                                }}/>
                            </div>
                        )}
                    </div>
                    <div className={css.phoneCodeBox}>
                        <p className={css.loginDesc + ' wineRed'}>Enter you code:</p>
                        {
                            data.login_type == 'phone' ? (
                                <Input placeholder='phone code'
                                       className={css.phoneCode}
                                       defaultValue={data.phone_code}
                                       onChange={(e) => {
                                           data.phone_code = e.target.value
                                       }}/>
                            ) : (
                                <Input placeholder='email code'
                                       className={css.phoneCode}
                                       defaultValue={data.email_code}
                                       onChange={(e) => {
                                           data.email_code = e.target.value
                                       }}/>
                            )
                        }
                        {data.phone_code_time == 0 ?
                            // <Button onClick={verify_phone_send_code}>send code</Button>
                            <span/>
                            : <CountDown
                                style={{color: 'red'}}
                                format={time => {
                                    return moment(time).format('mm:ss');
                                }}
                                target={data.phone_code_time}
                                onEnd={() => {
                                    data.phone_code_time = 0;
                                }}/>}
                    </div>
                    <span className={'hintGray'}>(Please also check your spam folder for the email).</span>
                    <Button
                        onClick={() => {
                            submitLogin(data.login_type)
                        }}
                        type='primary'
                        className={css.btn}>Log In</Button>
                </Col>
            </Col>
        )

    );
});

export default Login;
