import React, {useContext, createContext, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import PrivateRoute from "./private_route";
import Login from "../pages/login/login";
import HomeLogin from "../pages/home_login/home_login";
import Register from "../pages/register/register";
import Home from "../pages/home/home";
import Header from "../component/header/header";
import AddOrder from "../pages/add_order/add_order";
import EditOrder from "../pages/edit_order/edit_order";
import Person from "../pages/person/person";
import AddPaySubscribe from "../pages/person/add_pay_subscribe/add_pay_subscribe";
import PayResult from "../pages/pay_result/pay_result";
import ChangeBookTime from "../pages/edit_order/change_book_time/change_book_time";
import CancelOrder from "../pages/cancel_order/cancel_order";
import PrepareOrderStart from "../pages/prepare_order_start/prepare_order_start";
import OrderActive from "../pages/order_active/order_active";
import Welcome from "../pages/welcome/welcome";
import Default_week_work from "../pages/default_week_work/default_week_work";
import Future_work_time from "../pages/future_work_time/future_work_time";
import OrderEvaluate from "../pages/order_evaluate/order_evaluate";
import ResetPsw from "../pages/reset_psw/reset_psw";
import RegisterResult from "../pages/register/register_result/register_result";
import RegisterAddInfo from "../pages/register/register_add_info/register_add_info";
import CashOrderList from "../pages/cash_order_list/cash_order_list";
import OrderList from "../pages/order_list/order_list";
import MoneyCutOf from "../pages/money_cut_of/money_cut_of";

let config = require('commonProject/config/config');

function MyRoute() {
    let route =
        <Router>
            <div className='appContainer'>
                <Header/>
                <Switch>
                    <PrivateRoute path="/login/:roleType">
                        <Login/>
                    </PrivateRoute>
                    <Route path="/register">
                        <Register/>
                    </Route>
                    <PrivateRoute path="/register_add_info">
                        <RegisterAddInfo/>
                    </PrivateRoute>
                    <PrivateRoute path="/home_login">
                        <HomeLogin/>
                    </PrivateRoute>
                    <PrivateRoute path="/add_order/:stepType">
                        <AddOrder/>
                    </PrivateRoute>
                    <PrivateRoute path="/edit_order">
                        <EditOrder/>
                    </PrivateRoute>
                    <PrivateRoute path="/cancel_order">
                        <CancelOrder/>
                    </PrivateRoute>
                    <PrivateRoute path="/prepare_order_start">
                        <PrepareOrderStart/>
                    </PrivateRoute>
                    <PrivateRoute path="/change_book_time">
                        <ChangeBookTime/>
                    </PrivateRoute>
                    <PrivateRoute path="/person">
                        <Person/>
                    </PrivateRoute>
                    <PrivateRoute path="/add_pay_subscribe">
                        <AddPaySubscribe/>
                    </PrivateRoute>
                    <PrivateRoute path="/order_active">
                        <OrderActive/>
                    </PrivateRoute>
                    <Router path="/pay_result">
                        <PayResult/>
                    </Router>
                    <PrivateRoute path="/welcome">
                        <Welcome/>
                    </PrivateRoute>
                    <PrivateRoute path="/default_week_work">
                        <Default_week_work/>
                    </PrivateRoute>
                    <PrivateRoute path="/future_work_time">
                        <Future_work_time/>
                    </PrivateRoute>
                    <PrivateRoute path="/order_evaluate">
                        <OrderEvaluate/>
                    </PrivateRoute>
                    <PrivateRoute path="/cash_order_list">
                        <CashOrderList/>
                    </PrivateRoute>
                    <PrivateRoute path="/order_list">
                        <OrderList/>
                    </PrivateRoute>
                    <PrivateRoute path="/money_cut_of">
                        <MoneyCutOf/>
                    </PrivateRoute>
                    <Router path="/reset_psw">
                        <ResetPsw/>
                    </Router>
                    <Router path="/register_result">
                        <RegisterResult/>
                    </Router>

                    <PrivateRoute path="/">
                        <Home/>
                    </PrivateRoute>
                    {/*<PrivateRoute path="/" render={() => {*/}
                    {/*    window.location.href = `${config.url.user_client_url + '/'}`;//主页和用户的主页是一样的*/}
                    {/*}}/>*/}

                    {/*<Router path="/">*/}
                    {/*    <Home/>*/}
                    {/*</Router>*/}
                    <Route render={() => <Redirect to="/"/>}/>
                </Switch>
            </div>
        </Router>;
    return route;
}

export default MyRoute;
