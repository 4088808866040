let mobx = require('mobx');
// create State object
let data = {
    arr_new_work_time: [],//新的工作时间数组
    arr_old_work_time: [],//旧的工作时间数组
    is_show_add_modal: false,
}
let futureWorkTime = mobx.observable(data);

// export default loginState;
module.exports = futureWorkTime;