import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useLocation, useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Button, Col, Input, Row, message, Form, Radio, Select, Checkbox, Divider, Modal, Image} from "antd";
import {Link} from "react-router-dom";
import css from "./register_result.module.less";
import CountDown from "ant-design-pro/lib/CountDown";
import right_svg from 'src/assets/img/common/right.svg';

let store = require('../../../store');
let api = require('src/api');
let data = store.persist.register;
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');
let loginHelper = require('src/utils/helper/login_helper');

function initData() {
    // data = store.persist.register;
}

// create observer
let RegisterResult = observer(function () {
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let register_type = params.type ? params.type : 'success';//注册结果
    useEffect(() => {
        initData();
    }, []);

    return (
        <Col className={css.containBox}>
            {
                register_type == 'success' ? (
                    <div>
                        <Col align={'middle'}><Image src={right_svg} preview={false} className={css.successLogo}/></Col>
                        <br/>
                        <p className={css.headTitleBox + ' wineRed'}>Your profile is complete!</p>
                        <p className={css.btn2}>What's Next?</p>
                        <p>* We will email you instruction on what is next step. Once an interview conducted, you will
                            be able to start to take calls.</p>
                        <Button className={css.btn} onClick={() => {
                            loginHelper.logout(history)
                        }}>Done</Button>
                    </div>
                ) : (
                    <div>

                    </div>
                )
            }
        </Col>
    );
});

export default RegisterResult;
