let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let order = {
    //获取订单列表
    // get_order_list(params) {
    //     return axios.get('/webapp/order/get_order_list', {params: params});
    // },

    // 获取提现订单列表
    get_cash_order_list(params) {
        return axios.get('/webapp/cash_order/get_cash_order_list', {params: params});
    },
    // 创建提现订单
    add_cash_order(params) {
        return axios.post('/webapp/cash_order/add_cash_order', params);
    },

}

module.exports = order;
