import React, {useEffect, useRef} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Checkbox, Col, Divider, List, message, Modal, Radio, Row, Spin, Table} from "antd";
import css from './home_login.module.less';
import {useHistory} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import MessageNotify from "../../component/messageNotify/messageNotify";
import ReactAudioPlayer from 'react-audio-player';
import newNotifyMp3 from 'src/assets/voice/newNotify.mp3';

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let orderHelper = require('commonProject/helper/order_helper');
let websocketHelper = require('commonProject/helper/websocket_helper');
let commonUtils = require('commonProject/utils/Common');
let refAudio;//引用的提示音播放器
let loginHelper = require('src/utils/helper/login_helper');

async function initData() {
    // let res = await api.interpreter.get_home_info();
    // if (res.code != 0) return;
    //更新口译师数据
    await api.interpreter.get_interpreter_info();

    data.yesterday_columns.push({title: "M/H", dataIndex: "minute", key: "minute", fixed: 'left'});
    //计算data.yesterday_columns
    for (let i = 0; i < 24; i++) { // assume 24 hours
        data.yesterday_columns.push({
            title: i.toString(),
            dataIndex: i.toString(),
            key: i.toString(),
        });
    }


    // data.dataAll = res.data;
    setTimeout(async () => {
        // 因为home info里面有获取口译师的信息，但是刷新时会让长连接断开，所以这里要延迟一下
        let resHomeInfo = await api.interpreter.get_home_info();
        if (resHomeInfo.code == 0) {
            data.dataAll = resHomeInfo.data;

            // 计算data.yesterday_data
            let tempArr = []
            for (let i = 0; i < 60; i += 5) { // assume 60 minutes
                const row = {key: i.toString(), minute: i};
                data.dataAll.yesterdayPeerIntpsStatistical.forEach(item => {
                    let hour = moment(item.ctime).hour();
                    let minute = moment(item.ctime).minute();
                    // console.log('hour', hour, 'minute', minute);
                    if (minute === i) {
                        row[hour] = item.available_intps.length;
                    }
                });
                data.yesterday_data.push(row);
            }

            // for (let i = 0; i < 60; i+=5) { // assume 60 minutes and step by 5
            //     const row = { key: i.toString(), minute: i };
            //     store.dataSource.forEach(item => {
            //         const hour = moment(item.ctime).hour();
            //         const minute = moment(item.ctime).minute();
            //         if (minute === i) {
            //             row[hour.toString()] = item.available_intps.length - 1;
            //         }
            //     });
            //     data.push(row);
            // }
        }
    }, 300);
    websocketHelper.cb_receive_order = cb_receive_order;
    websocketHelper.cb_connect_change = cb_connect_change;
    websocketHelper.initWebsocket({...store.persist.userInfo, token: store.persist.token});
    //如果已经有订单在执行了，就跳转到执行界面
    if (data.order?.is_interpreter_ready) {
        history.push('/order_active?id=' + data.order._id);
    }
    let userInfo = store.persist.userInfo;
    //跳到填写用户信息页面
    if (!userInfo.email
        || !userInfo.phone_num
        || !userInfo.first_name
        || !userInfo.second_name
        || !userInfo.work_phone_num
        || !userInfo.interpret_langs
        || !userInfo.zoom_link
    ) return history.push('/register_add_info');

    let resWorks = await api.interpreter.get_interpreter_work_time();
    // 口译师没设置工作时间的功能先关闭
    // if (resWorks.code == 0) {
    //     if (resWorks.data.length == 0) history.push('/future_work_time');
    // }

    setTimeout(function () {
        // refAudio.current.audioEl.current.play();
        // refAudio.current.audioEl.current.pause();
        // refAudio.current.audioEl.current.load();
    }, 100)

    // 如果当前时区和用户保存的时区Asia/Shanghai不一致，就弹窗提醒
    console.log('userInfo.timezone', userInfo.time_zone, );
    console.log('moment.tz.guess()', moment.tz.guess());
    // if (userInfo.time_zone != moment.tz.guess()) {
    //     Modal.info({
    //         title: 'Time zone mismatch',
    //         content: (
    //             <div>
    //                 <p>Your current time zone is {moment.tz.guess()}</p>
    //                 <p>Your saved time zone is {userInfo.time_zone}</p>
    //                 <p>Please contact us and check your time zone setting</p>
    //             </div>
    //         ),
    //         onOk() {
    //         },
    //     });
    // }


    setTimeout(async function () {
        data.is_show_local_time = false;
    }, 5 * 60 * 1000)
}

//接收到一条订单的推送的回调
async function cb_receive_order(order) {
    data.order = order;
    console.log('receive order', order)
    refAudio.current.audioEl.current.play();

    setTimeout(async function () {//规定时间内没有点击接受，就关闭通知，并弹窗提示，你在什么时候错过了一笔订单，然后现在被设置离线了
        if (!data.isClickReady && data.order) {
            refAudio.current.audioEl.current.pause();
            let params = {
                work_status: 0,
            }
            let res = await api.interpreter.update_interpreter(params);
            let res2 = await api.interpreter.updateTodayAwaitTime({minutes: -30});
            data.order = null;
            Modal.info({
                content: `You missed a call at ${moment().format('yyyy-MM-DD HH:mm a')}. Your work status is now changed to Break. You need to manually change it back to Ready.  To be fair to others, your waiting time is deducted by 30 minutes.`,
            })
        }
    }, 18 * 1000)

}

async function cb_connect_change(isConnected) {
    console.log('isConnected', isConnected)
    data.long_connect_status = isConnected ? 'connected' : 'disconnected';
    getUserInfo()
}

async function onClickReady() {
    let params = {
        work_status: 1,
    }
    let res = await api.interpreter.update_interpreter(params);
}

async function onClickBreak() {
    let params = {
        work_status: 0,
    }
    let res = await api.interpreter.update_interpreter(params);
}

async function onClickDecline() {
    onClickBreak();
    refAudio.current.audioEl.current.pause();
    let res = await api.interpreter.updateTodayAwaitTime({minutes: -15, order_id: data.order._id});
    if (res.code != 0) return;
    data.order = null;
}

async function onClickTakeCall() {
    // data.is_show_ready_dialog = !store.persist.is_never_show_ready_dialog;
    // if (data.is_show_ready_dialog) return;
    data.isClickReady = true;
    let res = await api.order.update_order({
        _id: data.order._id,
        is_interpreter_ready: true,
        interpreter: store.persist.userInfo._id
    });
    if (res.code != 0) return;
    message.success(res.msg);
    history.push('/order_active?id=' + data.order._id);
}

async function getUserInfo() {
    let res = await api.interpreter.get_interpreter_info();
    if (res.code != 0) return data.work_status = 'You are NOT online, please log out and log back in.';
    let work_status = store.persist.userInfo.work_status;
    //    work_status: {//工作状态 0没准备工作online或者break  1上线等待工作中waiting  2工作中talking  3匹配中matching
    switch (work_status) {
        case 0:
            data.work_status = 'You are now on break.';
            break;
        case 1:
            data.work_status = 'You are ready to take calls.';
            break;
        case 2:
            data.work_status = 'You are executing talking an order';
            break;
        case 3:
            data.work_status = 'You are matching an order';
            break;
        default:
            data.work_status = '';
            return;
    }
}

// create observer
let HomeLogin = observer(function () {
    history = useHistory();
    data = store.home_login;
    let userInfo = store.persist.userInfo;
    // 这里必须声明 textInput，这样 ref 才可以引用它
    refAudio = useRef(null);
    useEffect(() => {
        initData();
    }, []);

    // if (commonUtils.isEmpty(data.dataAll)) return <div/>

    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>Homepage</p>
            <ReactAudioPlayer src={newNotifyMp3}
                // autoPlay
                              ref={refAudio}
                              style={{display: 'none'}}
                              controls/>
            {/*<Button onClick={() => {*/}
            {/*    console.log('refAudio', refAudio.current.audioEl.current.baseURI);*/}
            {/*    refAudio.current.audioEl.current.play();*/}
            {/*}}>test</Button>*/}
            <div className={css.headTitle1}>Updated every 5 min:</div>
            <div>Peer interpreters waiting: {data.dataAll.waitingCount}</div>
            <div>Peer interpreters working: {data.dataAll.workingCount}</div>
            {/*<div>Yesterday wait reward: {userInfo.yesterday_wait_reward}</div>*/}
            <div>Waiting Bonus: {userInfo.total_wait_reward}</div>
            {data.is_show_local_time ? (
                <div style={{color: 'blue', fontWeight: 'bold'}}>Your local
                    time: {moment().format('yyyy-MM-DD hh:mm a')}</div>
            ) : null}
            <div><span className={css.headTitle1}>Your Pay Scale:</span> Level *, with * rating.</div>
            <div className={css.headTitle1}>Yesterday % of available Peer Interpreters:</div>
            {/*<div className={'hintGrayItalic'}>(this function will be available soon)</div>*/}
            <Button className={'greyBtn'} style={{width: '200px'}} onClick={() => {
                data.is_show_yesterday_dialog = true;
            }}>View Yesterday</Button>
            <Divider className={'gray'}/>
            <div>Your ID & first name: <span className={'bold'}>{userInfo.id2} - {userInfo.first_name}</span></div>

            {
                commonUtils.isEmpty(data.order) ? (
                    <div>
                        <Row
                            className={css.bottomBtnBox + ` ${data.long_connect_status == 'disconnected' ? css.disabled : ''}`}>
                            <div onClick={onClickReady}
                                 className={'greyBtn ' + `${store.persist.userInfo.work_status == 1 ? css.greenBtn : ''}`}>Ready
                            </div>
                            <div onClick={onClickBreak}
                                 className={'greyBtn ' + `${store.persist.userInfo.work_status == 0 ? css.redBtn : ''}`}>Break
                            </div>
                        </Row>
                        <div style={{marginTop: '10px'}}>Your status:
                            <span
                                style={{color: data.long_connect_status == 'connected' ? 'green' : 'red'}}>{data.long_connect_status == 'connected' ? ' online' : ' offline!'}</span>
                        </div>
                        <div className={'hintGrayItalic'}>(updated every few seconds)</div>
                    </div>
                ) : (
                    <div className={css.orderInfoBox}>
                        <div className={'bold'}>
                            A user is being assigned to you:
                        </div>
                        <div>Company: {data.order.user.company.company_name}</div>
                        <div>Department: {data.order.user.company.department}</div>
                        <div>User's Name: <span
                            className={'bold'}>{data.order.user.first_name}</span>
                        </div>
                        <div>Interpreting format: <span
                            className={'bold'}>{data.order.support_service_type.type_name}</span> (~{data.order.subscribe_time_length} min)
                        </div>
                        <Row className={css.takeCallBtnBox}>
                            <div onClick={onClickTakeCall} className={'greyBtn ' + css.takeCallBtn}>take call</div>
                            <div onClick={onClickDecline} className={'greyBtn ' + css.declineBtn}>decline</div>
                        </Row>
                    </div>
                )
            }
            <div className={'flexGrow1'}></div>

            {/*<div className={css.testIfOnlineBox}>*/}

            {/*    <Row className={css.bottomBtnBox}>*/}
            {/*        <div onClick={getUserInfo}*/}
            {/*             className={'greyBtn '}>test if online*/}
            {/*        </div>*/}
            {/*    </Row>*/}

            {/*    <div>*/}
            {/*        {data.work_status}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={css.yesterDayDialogBox}>
                <Modal title="yester day peer interpreters available"
                       className={css.containBox}
                       width={'100vw'}
                       visible={data.is_show_yesterday_dialog}
                       onCancel={() => {
                           data.is_show_yesterday_dialog = false;
                       }} footer={null}>
                    <Table columns={data.yesterday_columns} dataSource={data.yesterday_data}
                           pagination={{
                               pageSize: 12,
                           }}/>
                </Modal>
            </div>
        </Col>
    );
});

export default HomeLogin;
