import React from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Col, Dropdown, Image, Menu, Row} from "antd";
// import './header.module.less';
import headerCss from './messageNotify.module.less'
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory} from "react-router-dom";
import newNotifyMp3 from 'src/assets/voice/newNotify.mp3';

let store = require('src/store');
let history;
let menu;
let commonUtils = require('commonProject/utils/Common');
let api = require('src/api');

function init() {

}

let MessageNotify = observer(function () {
    history = useHistory();
    init();
    return (
        <Row className={headerCss.headerContainBox1}>
            <audio
                id={`audio${1}`}
                src={newNotifyMp3}
                preload={true}
                autoPlay={true}
            >
                <source src={newNotifyMp3} type="audio/mp3"/>
            </audio>

            {/*<audio src="http://neroht.com/MIKA - Lollipop.mp3" data-test="audio"></audio>*/}
            <p>ssss</p>
        </Row>
    )
})

export default MessageNotify;
