let config = require('../config/config');

class FreshdeskHelper {

    //导入freshdesk插件
    static loadPlugin() {
        var fc_JS = document.createElement('script');
        fc_JS.type = 'text/javascript';
        fc_JS.src = '"https://wchat.freshchat.com"/js/widget.js?t=' + Date.now();
        (document.body ? document.body : document.getElementsByTagName('head')[0]).appendChild(fc_JS);
        window.fcSettings = {token: config.freshdesk.token, host: config.freshdesk.host};
    }

    //初始化freshdesk插件
    static initPlugin() {
        function initFreshChat() {
            window.fcWidget.init({
                token: "77d0fd7b-d312-41bb-9033-b4c2c8a2983d",
                host: "https://wchat.freshchat.com"
            });
        }

        function initialize(i, t) {
            var e;
            i.getElementById(t) ? initFreshChat() :
                ((e = i.createElement("script")).id = t,
                    e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js",
                    e.onload = initFreshChat, i.head.appendChild(e))
        }

        function initiateCall() {
            initialize(document, "freshchat-js-sdk")
        }


        window.addEventListener ?
            window.addEventListener("load", initiateCall, !1) :
            window.attachEvent("load", initiateCall, !1);
    }

    //隐藏freshchat小部件
    static hideWidget() {
        console.log('hideWidget');
        const interval = setInterval(() => {
            if (window.fcWidget) {
                clearInterval(interval);
                window.fcWidget.hide();
            }
        }, 500);
    }
}

module.exports = FreshdeskHelper;
