import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Divider, message, Radio, Rate, Row} from "antd";
import css from './order_evaluate.module.less';
import {useHistory} from "react-router-dom";
import {useParams, useRouteMatch, useLocation} from "react-router";
import {loadStripe} from '@stripe/stripe-js';
import TextArea from "antd/lib/input/TextArea";
import {StarOutlined} from '@ant-design/icons';

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let order_id;

//订单评价页面

async function getOrder(id) {
    let res = await api.order.get_order_list({_id: id});
    if (res.code != 0 || res.data.length == 0) return;
    data.order = res.data.item[0];
}

function initData() {
    getOrder(order_id);
}

//提交评价  type:sample简单   detail详细
async function submit(type) {
    let rate = null;
    let params = {
        type: 2,//1 用户给口译师的评价   2口译师给用户的评价
        _id: order_id,
    };
    if (type == 'sample') {
        if (!data.rate) return message.error(`rate can't empty`)
        rate = data.rate;
    } else if (type == 'detail') {
        if (!data.rate_effective || !data.rate_effective || !data.rate_voice_accent) return message.error(`rate can't empty`);
        rate = Number(data.rate_effective).mul(0.4)
            .add(Number(data.rate_efficiency).mul(0.3))
            .add(Number(data.rate_voice_accent).mul(0.25)).toFixed(1);
        params.rate_effective = data.rate_effective;
        params.rate_efficiency = data.rate_efficiency;
        params.rate_voice_accent = data.rate_voice_accent;
    }
    params.rate = rate;
    if (data.evaluate_desc) params.evaluate_desc = data.evaluate_desc;
    let res = await api.order.add_order_evaluate(params);
    if (res.code != 0) return;
    message.success('evaluate success');
    commonUtils.historyPushRefresh(history,'/');
}

let Order_evaluate = observer(function () {
    data = store.order_active;
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    order_id = params.id;
    useEffect(() => {
        initData();
    }, []);

    if (commonUtils.isEmpty(data.order)) return <div/>
    return (
        <Col className={css.containBox}>
            <div>User:{data.order.user.user_name}</div>
            <div>{data.order.support_service_type.type_name} starting/ending time:
                {moment(data.order.real_start_time).format('HH:mm a')}
                ~ {moment(data.order.real_end_time).format('HH:mm a')}</div>
            <Divider style={{margin: '10px 0'}}/>
            {
                !data.is_show_detail ?
                    <div className={css.rateSampleBox}>
                        <div className={css.rateAll}>Rate this call: <span className={'hint'}>(required)</span></div>
                        <Rate character={<StarOutlined/>} onChange={value => {
                            data.rate = value;
                            console.log(data.rate, value)
                            submit('sample')
                        }
                        }/>
                        {/*<Button className={'greyBtn'} onClick={() => {*/}
                        {/*    submit('sample')*/}
                        {/*}}>submit</Button>*/}
                        <Divider style={{margin: '105px 0 98px 0'}}/>

                        <div>More ratings & comments:</div>
                        <div>(This is welcome and appreciative)</div>
                        <Button className={'greyBtnBold'} onClick={() => {
                            data.is_show_detail = true
                        }}>Show Me</Button>
                    </div> :
                    <div className={css.rateDetailBox}>
                        <div className={css.moreItems}>More rating & comments:</div>
                        <div className={css.itemRate}>
                            <Rate character={<StarOutlined/>} onChange={value => {
                                data.rate_effective = value;
                            }
                            }/>
                            <div><span className={'bold'}>Effectiveness</span>
                                {`: no voice cutoff (i.e. device < 2 feet away), and proper volume.`}</div>
                        </div>
                        <div className={css.itemRate}>
                            <Rate character={<StarOutlined/>} onChange={value => {
                                data.rate_efficiency = value;
                            }
                            }/>
                            <div><span className={'bold'}>Efficiency</span>
                                {`: no long sentences, no multiple sentences at a time, no jargon or explain jargon.`}</div>
                        </div>
                        <div className={css.itemRate}>
                            <Rate character={<StarOutlined/>} onChange={value => {
                                data.rate_voice_accent = value;
                            }
                            }/>
                            <div><span className={'bold'}>Voice & Accent</span>
                                {`: clear, proper speed and tone, pronunciation.`}</div>
                        </div>
                        <div className={css.moreItems}>Comment <span>(optional)</span>:</div>
                        <TextArea onChange={e => {
                            data.evaluate_desc = e.target.value;
                        }}/>
                        <Button className={'greyBtnBold'} onClick={() => {
                            submit('detail')
                        }}>Submit</Button>
                    </div>
            }


        </Col>
    );
});

export default Order_evaluate;
