import './App.css';
import React from 'react';
import MyRoute from "./router";

//初始化数字工具类
require('commonProject/utils/numUtlis');
require('commonProject/utils/init_property_method');
let store = require('./store');

class App extends React.Component {

    componentWillMount() {
        //获取mobx的数据
        let str = window.localStorage.getItem('persist');
        if (!str) return;
        let data = JSON.parse(str);

        let objDefault = JSON.parse(JSON.stringify(store.persist));
        //把新的数据和默认数据进行合并，防止有些数据没有加入保存到persist;
        let newObj = Object.assign(objDefault, data);

        let keys = Object.keys(newObj);
        for (let key of keys) {
            store.persist[key] = data[key];
        }
        // store.persist = JSON.parse(str); 这种方式会把persist的观察功能给覆盖了
    }

    componentDidMount() {
        //在页面刷新时将mobx里的信息保存到localStorage里
        window.addEventListener("beforeunload", () => {
            //保存mobx的persist数据
            window.localStorage.setItem('persist', JSON.stringify(store.persist));
        });
    }

    render() {
        return <MyRoute/>;
    }
}

export default App;
