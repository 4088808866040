let store = {
    login: require('./login'),
    register: require('./register'),
    home_login: require('./home_login'),
    add_order: require('./add_order'),
    edit_order: require('./edit_order'),
    cancel_order: require('./cancel_order'),
    prepare_order_start: require('./prepare_order_start'),
    order_active: require('./order_active'),
    welcome: require('./welcome'),
    default_week_work: require('./default_week_work'),
    person: require('./person'),
    future_work_time: require('./future_work_time'),
    reset_psw: require('./reset_psw'),


    persist: require('./persist'),//只有要保存的持久化的数据才放到persist里面
};
module.exports = store