import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, List, message, Radio, Row, Spin, Table} from "antd";
import css from './order_list.module.less';
import {useHistory} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import {useLocation} from "react-router";
import BackBtn from "../../component/back_btn/back_btn";

let mobx = require('mobx');
let moment = require('moment');
let columns;

let data = mobx.observable({
    loading: false,
    hasMore: true,
    orders: [],
    selectItemOrder: null,
    query: {
        page: 1,
        pageSize: 100,
    },
    total: 0,
});
let api = require('src/api');
let history;
let params;
let orderHelper = require('commonProject/helper/order_helper');
let store = require('src/store');
let commonUtils = require('commonProject/utils/Common');

async function getOrders() {
    let params = {
        page: data.query.page,
        pageSize: data.query.pageSize,
    }
    let res = await api.order.get_order_list(params);
    if (res.code != 0) return;
    data.orders = res.data.item;
    data.total = res.data.total;
}

function initData() {
    let userInfo = store.persist.userInfo;
    columns = [
        /**
         */
        {
            title: 'Start Date/Time',
            dataIndex: 'real_start_time',
            key: 'real_start_time',
            width: 150,
            render: (text, record) => {
                return moment(record.real_start_time).format('MM/DD HH:mm a');
            }
        },
        {
            title: 'Duration',
            dataIndex: 'total_time',
            key: 'total_time',
            width: 150,
            render: (text, record) => {
                return Number(record.total_time).add(record.admin_add_total_time ?? 0);
            }
        },
        {
            title: 'PayLevel',
            dataIndex: 'intp_lang_base_price',
            key: 'intp_lang_base_price',
            width: 150,
            render: (text, record) => {
                // 这个值 = intp_lang_base_price-intp_notify_on_wait_customer_reward-intp_notify_advance_reward
                let newValue = Number(
                    Number(record.intp_lang_base_price ?? 0)
                        .del(record.intp_notify_on_wait_customer_reward ?? 0)
                ).del(Number(record.intp_notify_advance_reward ?? 0));
                return `$${newValue}`;
            }
        },
        {
            title: 'SMS_Bonus',
            dataIndex: 'intp_notify_advance_reward',
            key: 'intp_notify_advance_reward',
            width: 150,
            render: (text, record) => {
                return `$${text}`;
            },
        },
        {
            title: 'Call_Bonus',
            dataIndex: 'intp_notify_on_wait_customer_reward',
            key: 'intp_notify_on_wait_customer_reward',
            width: 150,
            render: (text, record) => {
                return `$${text}`;
            },
        },
        {
            title: 'SubTotal',
            dataIndex: 'interpreter_price',
            key: 'interpreter_price',
            width: 150,
            render: (text, record) => {
                return `$${text}`;
            },
        }
    ]
    //跳到填写用户信息页面
    // if (!userInfo.email || !userInfo.phone_num) return history.push('/register_is_admin');
    getOrders();
}

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/order_list?page=' + page + '&pageSize=' + pageSize);
}

// create observer
let OrderList = observer(function () {
    history = useHistory();
    params = commonUtils.query2ObjParams(useLocation().search);

    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    return (
        <Col className={css.containBox}>
            <Col align='middle'>
            </Col>
            <p className={css.loginDesc}>Manage pre-bookings</p>
            <span className={css.head1}>My booking list </span>
            <p className={css.btnBox1}>(scroll up or down to view):</p>
            <BackBtn/>
            <Table
                // style={{display:'inline'}}
                // rowKey="_id"
                columns={columns}
                dataSource={[...data.orders]}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage
                }}
            />
        </Col>
    );
});

export default OrderList;
