// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerContainBox1_Alok1M .containBox1_T59XvP {\n  font-size: 20px;\n}\n.headerContainBox1_Alok1M .flexGrow1_PDvXSd {\n  flex-grow: 1;\n}\n.headerContainBox1_Alok1M .menuIcon_XLpFzP {\n  width: 5vw;\n  height: 5vw;\n}\n@media only screen and (min-width: 1024px) {\n  .headerContainBox1_Alok1M {\n    max-width: 1024px;\n    margin: 0 auto;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/component/header/header.module.less"],"names":[],"mappings":"AAAA;EAGI,eAAA;AADJ;AAFA;EAQI,YAAA;AAHJ;AALA;EAYI,UAAA;EACA,WAAA;AAJJ;AASA;EACE;IACE,iBAAA;IACA,cAAA;EAPF;AACF","sourcesContent":[".headerContainBox1 {\n\n  .containBox1 {\n    font-size: 20px;\n    //background-color: green;\n  }\n\n  .flexGrow1 {\n    flex-grow: 1;\n  }\n\n  .menuIcon {\n    width: 5vw;\n    height: 5vw;\n  }\n}\n\n// DESKTOP\n@media only screen and (min-width: 1024px) {\n  .headerContainBox1 {\n    max-width: 1024px;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainBox1": "headerContainBox1_Alok1M",
	"containBox1": "containBox1_T59XvP",
	"flexGrow1": "flexGrow1_PDvXSd",
	"menuIcon": "menuIcon_XLpFzP"
};
export default ___CSS_LOADER_EXPORT___;
