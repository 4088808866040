let mobx = require('mobx');
// create State object
let data = {
    intp_status: null,//口译师账号状态
    arr_intp_status: [//口译师账号状态数组   allow_change表示允许口译师进行操作的数据
        {status_name: 'disable', status: '0', allow_change: false},//禁用
        {status_name: 'enable and not ready work', status: '1', allow_change: true},//启用不准备接收工作，不会在设置默认工作时间后自动添加未来的工作时间进去
        {status_name: 'enable and ready work', status: '2', allow_change: true},//启用且接收未来的工作，和上面相反
    ],
    intp_work_status: null,//口译师工作状态
    arr_intp_work_status: [
        {status_name: 'off-line', status: '0', allow_change: true},//未上线
        {status_name: 'inline and waiting work', status: '1', allow_change: true},//上线等待工作中
        {status_name: 'inline and working', status: '2', allow_change: false},//正在工作
    ]
}
let person = mobx.observable(data);

// export default loginState;
module.exports = person;