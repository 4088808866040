// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_Kk5-co .listItem_SBIja1 {\n  display: flex;\n  flex-wrap: wrap;\n  color: red;\n}\n.containBox_Kk5-co .bottomBtnBox_elteJs {\n  justify-content: space-between;\n}\n.containBox_Kk5-co .itemBox_lk5C91 {\n  margin-bottom: 20px;\n}\n.containBox_Kk5-co .itemBox_lk5C91 .weekName_W2P9ds {\n  width: 80px;\n}\n.containBox_Kk5-co .itemBox_lk5C91 .timePickBox_X3qFS3 {\n  flex-grow: 1;\n  width: 40vw;\n  display: flex;\n  flex-direction: column;\n}\n.containBox_Kk5-co .itemBox_lk5C91 .timePickBox_X3qFS3 .timePickMiddleBox_e0nqzO {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://src/pages/default_week_work/default_week_work.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;EACA,UAAA;AAAJ;AAJA;EAQI,8BAAA;AADJ;AAPA;EAYI,mBAAA;AAFJ;AAVA;EAcM,WAAA;AADN;AAbA;EAkBM,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AAFN;AAnBA;EAwBQ,aAAA;AAFR","sourcesContent":[".containBox {\n  .listItem {\n    display: flex;\n    flex-wrap: wrap;\n    color: red;\n  }\n\n  .bottomBtnBox {\n    justify-content: space-between;\n  }\n\n  .itemBox {\n    margin-bottom: 20px;\n    .weekName {\n      width: 80px;\n    }\n\n    .timePickBox {\n      flex-grow: 1;\n      width: 40vw;\n      display: flex;\n      flex-direction: column;\n\n      .timePickMiddleBox {\n        display: flex;\n      }\n\n      .timePicker {\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_Kk5-co",
	"listItem": "listItem_SBIja1",
	"bottomBtnBox": "bottomBtnBox_elteJs",
	"itemBox": "itemBox_lk5C91",
	"weekName": "weekName_W2P9ds",
	"timePickBox": "timePickBox_X3qFS3",
	"timePickMiddleBox": "timePickMiddleBox_e0nqzO"
};
export default ___CSS_LOADER_EXPORT___;
