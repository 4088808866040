// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_6vuNlq .listItem_zDlhEC {\n  display: flex;\n  flex-wrap: wrap;\n  color: red;\n}\n.containBox_6vuNlq .bottomBtnBox_48Rn9c {\n  justify-content: space-between;\n}\n.containBox1_ovomL9 {\n  display: flex;\n  background-color: blue;\n}\n.containBox1_ovomL9 .box2_ptiG33 {\n  background: red;\n}\n.containBox1_ovomL9 .box2_ptiG33 .box3_U0h2uW {\n  background: green;\n}\n.containBox1_ovomL9 .box3_U0h2uW {\n  background: yellow;\n}\n", "",{"version":3,"sources":["webpack://src/pages/prepare_order_start/prepare_order_start.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;EACA,UAAA;AAAJ;AAJA;EAOI,8BAAA;AAAJ;AAIA;EACE,aAAA;EAEA,sBAAA;AAHF;AAAA;EAMI,eAAA;AAHJ;AAHA;EASM,iBAAA;AAHN;AANA;EAcI,kBAAA;AALJ","sourcesContent":[".containBox {\n  .listItem {\n    display: flex;\n    flex-wrap: wrap;\n    color: red;\n  }\n  .bottomBtnBox{\n    justify-content: space-between;\n  }\n}\n\n.containBox1 {\n  display: flex;\n  //flex-direction: column;\n  background-color: blue;\n\n  .box2 {\n    background: red;\n\n    .box3 {\n      background: green;\n    }\n  }\n\n  .box3 {\n    background: yellow;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_6vuNlq",
	"listItem": "listItem_zDlhEC",
	"bottomBtnBox": "bottomBtnBox_48Rn9c",
	"containBox1": "containBox1_ovomL9",
	"box2": "box2_ptiG33",
	"box3": "box3_U0h2uW"
};
export default ___CSS_LOADER_EXPORT___;
