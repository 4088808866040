// const WebSocket = require('ws');
//
// const ws = new WebSocket('ws://www.host.com/path');
//
// ws.on('open', function open() {
//     ws.send('something');
// });
//
// ws.on('message', function incoming(data) {
//     console.log(data);
// });
//
// ws.on('close', function close() {
//     console.log('disconnected');
// });
let decrypt = require('../utils/decrypt');
var socket; //websocket的实例
var lockReconnect = false; //避免重复连接
let config = require('commonProject/config/config');

let objExport = {
    send,
    initWebsocket,
    cb_receive_order: null,//接收订单的回调
    cb_receive_chat_msg: null,//接收普通聊天消息的回调
    cb_receive_order_change: null,//接收到订单状态改变回调
    cb_connect_change: function () {
    },//连接状态改变回调
};

function initWebsocket(user) { //新建websocket的函数 页面初始化 断开连接时重新调用
    var wsUrl = config.websocket.url;
    socket = new WebSocket(wsUrl);
    console.log('wsUrl', wsUrl)
    socket.onerror = function (event) {
        //console.log('websocket服务出错了');
        reconnect(wsUrl, user);
        objExport.cb_connect_change(false);
    };
    socket.onclose = function (event) {
        //console.log('websocket服务关闭了');
        reconnect(wsUrl, user);
        objExport.cb_connect_change(false);
    };
    socket.onopen = function (event) {
        heartCheck.reset().start(); //传递信息
        let s = {
            action_type: "connect",
            token: user.token,
            role_type: user.role_type,
            _id: user._id,
        };
        //在连接创建完成后发送一条信息
        socket.send(decrypt.aesEncrypt(JSON.stringify(s)));
        objExport.cb_connect_change(true);
    };
    socket.onmessage = function (event) {
        //如果获取到消息，心跳检测重置
        //拿到任何消息都说明当前连接是正常的
        //console.log('websocket服务获得数据了');
        //接受消息后的UI变化
        doWithMsg(event.data);
        heartCheck.reset().start();
    };

    //收到消息推送
    function doWithMsg(msg) {
        console.log(msg);
        try {
            let data = JSON.parse(msg);
            data = data.data;
            let action_type = data.action_type;
            //被分配到一条订单
            if (action_type == config.websocket.action_type.receive_order) {
                //调用回调方法，内容由外部传入
                objExport.cb_receive_order(data.msg.order);
                //接收到一条普通订单
            } else if (action_type == config.websocket.action_type.receive_chat_msg) {
                objExport.cb_receive_chat_msg(data.msg);
                //接收到订单状态改变
            } else if (action_type == config.websocket.action_type.receive_order_change) {
                objExport.cb_receive_order_change(data.msg);
            }
        } catch (e) {
        }
    }
}

function reconnect(url, user) {
    if (lockReconnect) return;
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    setTimeout(function () {
        initWebsocket(user);
        lockReconnect = false;
    }, 2000);
}

//心跳检测
var heartCheck = {
    timeout: 10000, //10秒
    timeoutObj: null,
    serverTimeoutObj: null,
    reset: function () {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        return this;
    },
    start: function () {
        var self = this;
        this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            socket.send("心跳测试");
            self.serverTimeoutObj = setTimeout(function () { //如果超过一定时间还没重置，说明后端主动断开了
                socket.close(); //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
            }, 60 * 10000)
        }, this.timeout)
    }
}

function send(msg) {
    console.log('send', msg)
    let params = {
        data: msg
    }
    socket.send(decrypt.aesEncrypt(JSON.stringify(params)));
}

module.exports = objExport;
