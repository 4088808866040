import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Col, Collapse, Divider, Input, message, Radio, Row, Select, Table} from "antd";
import css from './money_cut_of.module.less';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {Option} from "antd/es/mentions";

let api = require('src/api');
let moment = require('moment');
let store = require('../../store');
let mobx = require('mobx');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let params;
let money_cut_of_id;
const {Panel} = Collapse;

let data = mobx.observable({
    money_cut_ofs: [],
    query:{
        page: 1,
        pageSize: 100,
    },
    total: 0,
    columns:[
        {
            title: 'money before',
            dataIndex: 'money_before',
            key: 'money_before',
            width: 150,
        },
        {
            title: 'money after',
            dataIndex: 'money_after',
            key: 'money_after',
            width: 150,
        },
        {
            title: 'create time',
            dataIndex: 'ctime',
            key: 'ctime',
            valueType: 'dateTime',
            width: 160,
            render(ctime) {
                return moment(ctime).format('YYYY-MM-DD HH:mm'); // 注意这里改成了大写的 YYYY
            }
        },
    ]
});

async function get_money_cut_of(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.user.get_money_cut_of(data.query);
    if (res.code != 0) return;
    data.money_cut_ofs = res.data.item;
    data.total = res.data.total;
}

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/money_cut_of?page=' + page + '&pageSize=' + pageSize);
}

// 每周资金截断表
let MoneyCutOf = observer(function () {
    history = useHistory();
    params = commonUtils.query2ObjParams(useLocation().search);
    useEffect(async () => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        await get_money_cut_of(data.query.page, data.query.pageSize);
    }, []);

    if (commonUtils.isEmpty(data.money_cut_ofs)) return <div/>
    return (
        <div className={css.containBox}>
            <div>user money list</div>
            <Table
                // style={{display:'inline'}}
                // rowKey="_id"
                columns={data.columns}
                dataSource={data.money_cut_ofs}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage
                }}
            />
        </div>
    );
})

export default MoneyCutOf;
