import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Card, Col, DatePicker, Divider, Image, InputNumber, List, message, Modal, Radio, Row} from 'antd';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";

let store = require('src/store');
let config = require('commonProject/config/config');
let api = require('src/api');
let moment = require('moment');
let history;
let commonUtils = require('commonProject/utils/Common');
let queryParams;

function submit() {
    let formData = store.persist.add_order.formData;
    let certs = formData.chooseCerts.map(item => {
        return item._id;
    });
    let params = {
        interpret_lang_user_need: formData.interpret_lang_user_need,
        support_service_type: formData.support_service_type._id,
        subscribe_type: 1,
        subscribe_time_length: formData.subscribe_time_length,
        subscribe_time: formData.subscribe_time,
        certs: certs.join(','),
    };
    //有订单id表示是进行更改订单数据
    if (queryParams.id) {
        delete params.certs;
        params._id = queryParams.id;
        api.order.update_order(params).then(res => {
            if (res.code != 0) return;
            Modal.success({content: 'update order message success'});
            clearFormData();
            history.push('/home_login');
        })
    } else {
        api.order.add_order(params).then(res => {
            if (res.code == 0) {
                Modal.success({content: 'Thank you for pre-booking. Now you can either pre-book another service here, or manage your existing ones.'});
                clearFormData();
                history.push('/home_login');
            }
        });
    }

}

function clearFormData() {
    let formData = store.persist.add_order.formData;
    let values = Object.values(formData);
    values.map(item => {
        item = null;
    })
}

// create observer
let BookConfirm = observer(function () {
    history = useHistory();
    let formData = store.persist.add_order.formData;
    queryParams = commonUtils.query2ObjParams(useLocation().search);

    useEffect(() => {
    }, [])

    let prebookingTimeData = store.add_order.prebooking_time;
    let duration = moment.duration(formData.subscribe_time_length, 'minutes');
    return (
        <Col>
            <p>step 4 of 4:Pre-booking</p>
            <Divider/>
            <p>Summary:</p>
            <p>{`You chose:${formData.chooseLang.language_name}-${formData.support_service_type.type_name}`}</p>
            <p>{`Service Date:${moment(formData.subscribe_time).format('MM/DD/yyyy HH:mm:ss dddd')}`}</p>
            <p>{`Service Duration:${duration.hours()} hr + ${duration.minutes()} min`}</p>
            <Divider/>
            <p>Is the pre-booking info accurate?</p>
            <Button onClick={submit}>Confirm</Button>
        </Col>
    );
})

export default BookConfirm;
