import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import css from './register_add_info.module.less';
import { Button, Checkbox, Col, Form, Input, message, Radio, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useLocation } from "react-router";
import BackBtn from "../../../component/back_btn/back_btn";
import { func } from "prop-types";

let store = require('../../../store');
let api = require('src/api');
let data = store.register;
let dataPersist;//缓存数据
let userInfo = {};
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment-timezone');
let config = require('commonProject/config/config');
let queryParams;

function submitUpdateUser() {
    let formData = data.formData;
    console.log('formData', JSON.parse(JSON.stringify(formData)));
    formData.user_name = formData.email;
    if (!formData.notify_on_wait_customer) {
        formData.notify_on_wait_customer = 'no_notify';
    } else if (formData.notify_on_wait_customer == 'by_call') {
        // 如果选择了电话通知，那么电话号码必填
        if (!formData.phone_num) return message.error('You have some field missing');
    }
    if (!formData.time_zone ||
        !formData.first_name ||
        !formData.second_name ||
        !formData.gender ||
        !formData.street_address ||
        !formData.city ||
        !formData.state ||
        !formData.zip_code ||
        !formData.work_phone_num ||
        commonUtils.isEmpty(formData.chooseLangs) ||
        !formData.zoom_link ||
        !formData.is_agree
    ) return message.error('You have some field missing');

    let params = JSON.parse(JSON.stringify(formData));
    if (params.chooseCerts) {
        let tempCerts = params.chooseCerts.map(item => {
            return item._id;
        })
        params.certs = tempCerts.join(',');
    }
    let tempLangs = params.chooseLangs.map(item => {
        return item._id;
    })
    params.interpret_langs = tempLangs.join(',');

    api.interpreter.update_interpreter(params).then(res => {
        let resData = res;
        if (resData.code !== 0) return;
        message.success(resData.msg);
        // history.push('/login/intepreter');
        store.persist.register.data = {};
        history.push('/register_result?type=success');
    })
}

function submitUpdateUserHalf() {
    let formData = data.formData;
    let tempParams = JSON.parse(JSON.stringify(formData));
    let params = {
        _id: userInfo._id,
        notify_on_wait_customer: tempParams.notify_on_wait_customer,
        interpreter_type: tempParams.interpreter_type,
        advance_notice: tempParams.advance_notice,
        zoom_link: tempParams.zoom_link
    };

    api.interpreter.update_interpreter(params).then(res => {
        let resData = res;
        if (resData.code !== 0) return;
        message.success(resData.msg);
        setTimeout(() => {
            // commonUtils.historyPushRefresh(history, '/register_add_info?type=view');
            history.goBack();
        }, 50);
        // history.push('/login/intepreter');
    })
}

async function saveData() {
    let formData = data.formData;
    console.log('formData', JSON.parse(JSON.stringify(formData)));
    formData.user_name = formData.email;
    console.log('JSON.stringify(formData)', JSON.stringify(formData))
    store.persist.register.data = formData;
    await commonUtils.timeSleep(500);
    history.go();
}

function getCerts() {
    api.order.get_cert_list().then(res => {
        data.chooseCerts = res.data;
    })
}

function getLanguage() {
    api.order.get_interpret_lang_list().then(res => {
        data.chooseLangs = res.data;
    })
}

//把保存的数据复制到formData里面
function copySaveDataToFormData() {
    dataPersist = store.persist.register.data;//缓存数据
    if (commonUtils.isEmpty(dataPersist)) return;
    let keys = Object.keys(dataPersist);
    for (let key of keys) {
        data.formData[key] = dataPersist[key];
    }
}

//根据key返回对应的字符串
function getNotifyTypeStr(key) {
    let str = '';
    switch (key) {
        case 'by_call':
            str = 'Notify me via auto-voice call';
            break;
        case 'by_sms':
            str = 'Notify me via SMS';
            break;
        case 'no_notify':
            str = 'Do not notify me';
            break;
    }
    return str;
}

function getAdvanceNotifyStr(key) {
    let str = '';
    switch (key) {
        case 'notify':
            str = 'Notify me via SMS';
            break;
        case 'no_notify':
            str = 'Do not notify me';
            break;
    }
    return str;
}


// create observer
let RegisterAddInfo = observer(function () {
    history = useHistory();
    queryParams = commonUtils.query2ObjParams(useLocation().search);

    useEffect(() => {
        getCerts();
        getLanguage();
        userInfo = store.persist.userInfo;
        data.formData.time_zone = commonUtils.getClientTimeZone();
        data.formData.notify_on_wait_customer = userInfo.notify_on_wait_customer;
        data.formData.advance_notice = userInfo.advance_notice;
        data.formData.interpreter_type = userInfo.interpreter_type;
        dataPersist = store.persist.register.data;//缓存数据
        const unlisten = history.listen(async (newLocation, action) => {
            if (action === 'POP') {
                // 在这里重新获取数据
                window.location.reload();
            }
        });
        //先把save功能去掉
        // copySaveDataToFormData();

        return () => {
            unlisten();
        }
    }, []);

    if (commonUtils.isEmpty(data.chooseCerts)
        || commonUtils.isEmpty(data.chooseLangs)) return <div />
    if (queryParams.type === 'edit') {
        return (
            <Col aligin='middle' className={css.box}>
                <div className={css.headTitleBox + ' wineRed'}>Profile Edit</div>
                <div className={css.titleHint}>To change your profile for content not listed here, please email
                    interpreter@interpretown.com
                </div>
                <p />
                <Form name='registerForm' initialValues={{}} onFinish={submitUpdateUserHalf}>
                    <Form.Item label={<span className={css.formItemLable}>Notification on Waiting Customer</span>}>
                        <Radio.Group value={data.formData.notify_on_wait_customer} onChange={(e) => {
                            data.formData.notify_on_wait_customer = e.target.value
                        }}>
                            <div>
                                <Radio value='by_call'>{getNotifyTypeStr('by_call')}</Radio>
                            </div>
                            {/*<div>*/}
                            {/*    <Radio value='by_sms'>{getNotifyTypeStr('by_sms')}</Radio>*/}
                            {/*</div>*/}
                            <div>
                                <Radio value='no_notify'>{getNotifyTypeStr('no_notify')}</Radio>
                            </div>
                        </Radio.Group>
                        <div className={' hintGray'}>We will notify at most three interpreters at a time.</div>
                    </Form.Item>
                    <Form.Item
                        label={<span className={css.formItemLable}>Notification on Service Advance Notice</span>}>
                        <Radio.Group value={data.formData.advance_notice} onChange={(e) => {
                            data.formData.advance_notice = e.target.value
                        }}>
                            <div>
                                <Radio value='notify'>{getAdvanceNotifyStr('notify')}</Radio>
                            </div>
                            <div>
                                <Radio value='no_notify'>{getAdvanceNotifyStr('no_notify')}</Radio>
                            </div>
                        </Radio.Group>
                        <div className={' hintGray'}>We will text message you when there is a customer send us advance
                            notice of a service. We text three interpreters at a time.
                        </div>
                    </Form.Item>
                    <Form.Item label={<span className={css.formItemLable}>Interpreter Type You Want To Be</span>}>
                        <p className={'hintGrayItalic'}>Backup interpreter gets 16% more pay for every call, but will
                            get
                            calls only when there is no primary interpreters available.</p>
                        <Radio.Group
                            value={data.formData.interpreter_type}
                            onChange={e => {
                                data.formData.interpreter_type = e.target.value;
                            }}>
                            {
                                [{ name: 'Primary', value: 'pi' },
                                { name: 'Backup', value: 'bi' },].map(item => {
                                    return (
                                        <Radio value={item.value}>{`${item.name}`}</Radio>
                                    )
                                })
                            }
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={<span className={css.formItemLable}>Your Zoom for Healthcare Link (for VRI)</span>}>
                        <Input placeHolder='Copy and Paste here your "Zoom for Healthcare" Link'
                            value={data.formData.zoom_link}
                            onChange={(e) => {
                                data.formData.zoom_link = e.target.value
                            }} />
                    </Form.Item>
                    <Button className={css.btn + ' greyBtn'} htmlType="submit">
                        Agree and Submit
                    </Button>
                    <BackBtn />
                </Form>
            </Col>
        )
    } else if (queryParams.type === 'view') {
        if (commonUtils.isEmpty(userInfo.address)) return <div />
        return (
            <Col aligin='middle' className={css.box}>
                <div className={css.headTitleBox + ' wineRed'}>Interpreter Profile</div>
                <div className={'hintGrayItalic'} style={{ whiteSpace: 'nowrap' }}>To change your profile, email
                    interpreter@interpretown.com
                </div>
                <div className={'greyBtn ' + css.btnEdit} onClick={() => {
                    history.push('/register_add_info?type=edit');
                }}>Edit
                </div>
                <div className={'greyBtn ' + css.btnEdit} style={{ marginLeft: '10px' }} onClick={() => {
                    history.go(-1)
                }}>Back
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Your Name</div>
                    <div className={css.contentBox}>
                        <div className={css.contentBlue}>Interpreter ID: {userInfo.id2}</div>
                        <div className={css.contentBlue}>Name: {userInfo.first_name}, {userInfo.second_name}</div>
                        <div className={css.contentBlue}>Email: {userInfo.email}</div>
                        <div className={css.contentBlue}>Gender: {userInfo.gender}</div>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Mailing Address</div>
                    <div className={css.contentBox}>
                        <div className={css.contentBlue}>Street Address: {userInfo.address.street_address}</div>
                        <div className={css.contentBlue}>City, state, zip code:{' '}
                            {userInfo.address.city}, {userInfo.address.state}, {userInfo.address.zip_code}</div>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Home Address Zip Code (optional)</div>
                    <div className={css.contentBlue}>Street Address: {userInfo.home_address_zip_code}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Cell Phone or SMS App Number</div>
                    <div className={css.contentBlue}>{userInfo.phone_num}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Work Phone</div>
                    <div className={css.contentBlue}>Phone number: {userInfo.work_phone_num}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Notification on Waiting Customer</div>
                    <div className={'hintGrayItalic'}>We will notify at most three interpreters at a time.</div>
                    <div className={css.contentBlue}>{getNotifyTypeStr(userInfo.notify_on_wait_customer)}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Notification on Customer Advance Notice</div>
                    <div className={'hintGrayItalic'}>We will SMS notify three interpreters at a time.</div>
                    <div className={css.contentBlue}>{getAdvanceNotifyStr(userInfo.advance_notice)}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Language Provided</div>
                    <div className={css.contentBlue}>{userInfo.interpret_langs?.[0]?.language_name}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Short Bio (optional)</div>
                    <div className={css.contentBlue}>{userInfo.short_bio}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Special Certifications</div>
                    <div className={css.contentBlue}>{userInfo.certs.map(item => {
                        return item.cert_desc + ', '
                    })}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Your Time Zone</div>
                    <div className={css.contentBlue}>{userInfo.time_zone}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Interpreter Type</div>
                    <div
                        className={css.contentBlue}>{userInfo.interpreter_type == 'bi' ? 'Backup Interpreter' : 'Primary Interpreter'}</div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.title}>Zoom for Healthcare Account (for VRI)</div>
                    <div className={css.contentBlue}>{userInfo.zoom_link}</div>
                </div>
            </Col>
        )
    }
    return (
        <Col aligin='middle' className={css.box}>
            <div className={css.headTitleBox + ' wineRed'}>Interpreter Profile</div>
            {/*<div className={css.titleHint}>-- you can Save and come back later</div>*/}
            {/*<div className={css.titleHint}>-- once submitted, you cannot make changes</div>*/}
            <div className={css.titleHint}>Please finish everything in one go and sumbit. Thank you.</div>
            <p />

            <Form name='registerForm' initialValues={{}} onFinish={submitUpdateUser}>
                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Your Name</span>} name='name'>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Input placeHolder='First Name' value={data.formData.first_name} onChange={(e) => {
                            data.formData.first_name = e.target.value
                        }} />
                    </div>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Input placeHolder='Last Name' value={data.formData.second_name} onChange={(e) => {
                            data.formData.second_name = e.target.value
                        }} />
                    </div>
                    <span className={' hintGray'}>It needs to match the name on your government ID for 1099 tax form purpose.</span>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Your Email</span>} name='email'>
                    <Input placeHolder='Email' defaultValue={userInfo.email} disabled={true} onChange={(e) => {
                        data.formData.email = e.target.value
                    }} />
                    <span className={'hintGray'}>We'll use it for various communications.</span>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Your gender</span>} name='gender'>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Radio.Group defaultValue={true} value={data.formData.gender} onChange={(e) => {
                            data.formData.gender = e.target.value
                        }}>
                            <Radio value='female'>Female</Radio>
                            <Radio value='male'>Male</Radio>
                            <Radio value='other'>Others</Radio>
                        </Radio.Group>
                    </div>
                    <span className={' hintGray'}>Some users request this information for their clients.</span>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Mailing Address</span>} name='mailing_address'>
                    <span className={' hintGray'}>For sending 1099 tax form.</span>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Input placeHolder='Street Address' value={data.formData.street_address} onChange={(e) => {
                            data.formData.street_address = e.target.value
                        }} />
                    </div>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Input placeHolder='City' value={data.formData.city} onChange={(e) => {
                            data.formData.city = e.target.value
                        }} />
                    </div>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Select placeholder='State' value={data.formData.state} onChange={(value) => {
                            data.formData.state = value
                        }}>
                            {data.cities.map(item => {
                                return (
                                    <Select.Option value={item}>
                                        {item}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Input placeHolder='Zip Code' value={data.formData.zip_code} onChange={(e) => {
                            data.formData.zip_code = e.target.value
                        }} />
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Home Address Zip Code  (<span
                    className={'lightBlue'}>optional</span>)</span>}>
                    <p className={' hintGray'}>For calculating gas mileage if you choose to offer on-site interpreting
                        (OSI) service in the future. Only Zip code is needed rather than detailed address.</p>
                    <div className={'flex'}>
                        <Input placeHolder='Zip Code' value={data.formData.home_address_zip_code} onChange={(e) => {
                            data.formData.home_address_zip_code = e.target.value
                        }} />
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}><span className={'bold'}>Cell Phone</span>, or VoIP Phone Number</span>} name='phone_num'>
                    <p className={' hintGray'}> We will text you for important messages when necessary, e.g. expected
                        customer service date/time, website is down, customer is waiting, etc. You can use either a cell
                        phone number or a Text App number, e.g. free Google Voice, TextFree App, TextNow App (free or
                        $4.99/year), etc.
                        You can choose to disable the SMS notice, once your account is approved, under the menu "Profile
                        and Settings".</p>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Input placeHolder='Cell Phone number (or Text App number)'
                            value={data.formData.phone_num}
                            onChange={(e) => {
                                data.formData.phone_num = e.target.value
                            }} />
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Auto-Voice-Call Number</span>} name='phone_num'>
                    <p className={' hintGray'}>We will auto-voice-call you for urgent messages when necessary, e.g.
                        website is down, users is waiting, etc. You can use either the above cell phone number
                        (recommended), or another landline number.
                        You can choose to disable the auto-voice-call notice, once your account is approved, under the
                        menu "Profile and Settings".</p>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Input placeHolder='Cell #, Text App #, or landline #' value={data.formData.work_phone_num}
                            onChange={(e) => {
                                data.formData.work_phone_num = e.target.value
                            }} />
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Notification on Waiting Customer</span>}>
                    <div className={' hintGray'}>If a customer is waiting online for your language, do you prefer to be
                        notified so that you can log in? You can change this any time later. We will notify at most
                        three interpreters at a time.
                    </div>
                    <div className={'flex'}>
                        <Radio.Group value={data.formData.notify_on_wait_customer} onChange={(e) => {
                            data.formData.notify_on_wait_customer = e.target.value
                        }}>
                            <div>
                                <Radio value='by_call'>{getNotifyTypeStr('by_call')}</Radio>
                            </div>
                            <div>
                                <Radio value='no_notify'>{getNotifyTypeStr('no_notify')}</Radio>
                            </div>
                        </Radio.Group>
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Notification on Customer Advance Notice</span>}>
                    <div className={' hintGray'}>We will text message you when there is a customer send us advance
                        notice of a service. We text three interpreters at a time.
                    </div>
                    <div className={'flex'}>
                        <Radio.Group value={data.formData.advance_notice} onChange={(e) => {
                            data.formData.advance_notice = e.target.value
                        }}>
                            <div>
                                <Radio value='notify'>{getAdvanceNotifyStr('notify')}</Radio>
                            </div>
                            <div>
                                <Radio value='no_notify'>{getAdvanceNotifyStr('no_notify')}</Radio>
                            </div>
                        </Radio.Group>
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Language You Provide</span>}>
                    <p className={' hintGray'}>The interpreting language other than English in your interpreting
                        language pair, e.g. Spanish, Japanese, etc.</p>
                    <div className={'flex'}>
                        <Checkbox.Group value={data.formData.chooseLangs} onChange={arr => {
                            data.formData.chooseLangs = arr;
                        }}>
                            {
                                data.chooseLangs.map(item => {
                                    return (
                                        item.language_name != 'Test' ? (
                                            <Checkbox value={item}>{`${item.language_name}`}</Checkbox>
                                        ) : (<Checkbox value={item}
                                            className={'hintGrayItalic'}>{`${item.language_special_desc}`}</Checkbox>)
                                    )
                                })
                            }
                        </Checkbox.Group>
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Short Bio <span className={'lightBlue'}>(optional)</span></span>}>
                    <div className={' hintGray'}>The info here mainly acts as a short bio and will be visible to VIP
                        customers who may choose only your service. Keep it short and concise. You may list here your
                        official interpreting certificate(s) with ID #, and/or some brief experiences and explanations
                        of your highest qualification as an interpreter.
                    </div>
                    <div className={'flex'}>
                        <TextArea rows={4}
                            maxLength={800}
                            placeHolder='Short bio, e.g. official interpreting certificate, brief work experience, awards, etc.'
                            value={data.formData.short_bio}
                            onChange={(e) => {
                                data.formData.short_bio = e.target.value
                            }} />
                    </div>
                    <div className={'lightBlue'}>({800 - data.formData.short_bio?.length} characters left)</div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Certification or Qualification</span>}>
                    <div className={'flex'}>
                        <Checkbox.Group value={data.formData.chooseCerts} onChange={arr => {
                            data.formData.chooseCerts = arr;
                        }}>
                            {
                                data.chooseCerts.map(item => {
                                    return (
                                        <Checkbox value={item}>{`${item.cert_desc}(${item.cert_name})`}</Checkbox>
                                    )
                                })
                            }
                        </Checkbox.Group>
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Your Time Zone</span>} name='time_zone'>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <div>{data.formData.time_zone ? `${data.formData.time_zone} ${moment().tz(data.formData.time_zone).format('YYYY-MM-DD hh:mm a')}` : ''}</div>
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Interpreter Type You Want To Be</span>}>
                    <p className={'hintGrayItalic'}>Backup interpreter gets 16% more pay for every call, but will get
                        calls only when there is no primary interpreters available.</p>
                    <div className={'flex'}>
                        <Radio.Group
                            value={data.formData.interpreter_type}
                            onChange={e => {
                                data.formData.interpreter_type = e.target.value;
                            }}>
                            {
                                [{ name: 'Primary', value: 'pi' },
                                { name: 'Backup', value: 'bi' },].map(item => {
                                    return (
                                        <Radio value={item.value}>{`${item.name}`}</Radio>
                                    )
                                })
                            }
                        </Radio.Group>
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Your Zoom for Healthcare Link (for VRI)</span>}>
                    <div className={'flex'}>
                        <Input placeHolder='Copy and Paste here your "Zoom for Healthcare" Link'
                            value={data.formData.zoom_link}
                            onChange={(e) => {
                                data.formData.zoom_link = e.target.value
                            }} />
                    </div>
                </Form.Item>

                <Form.Item className={css.headTitle} label={<span className={css.formItemLable}>Agree service</span>}>
                    <div className={'flex'}>
                        <label className={'required'} />
                        <Checkbox
                            value={data.formData.is_agree}
                            onChange={(e) => {
                                data.formData.is_agree = e.target.checked
                            }}>
                            In order to complete your registration, You are required to electronically accept these
                            <a href={`${config.url.user_client_url}/company_home?type=termsAndConditions`} target="_blank"
                                className={'underline'}> Terms
                                and Conditions </a>
                            . You acknowledge that You have carefully reviewed these terms, to which You a
                            party, fully understand and are aware of these terms, and understand your rights, obligations
                            and responsibilities hereunder. You acknowledge that Company has presented You with the
                            opportunity to review and agree to these terms. By continuing, You agree to these Terms and
                            Conditions of Our Service, and
                            <a href={`${config.url.user_client_url}/company_home?type=privacyPolicy`} target="_blank"
                                className={'underline'}>Privacy
                                Policy</a>.
                        </Checkbox>
                    </div>
                </Form.Item>
                <Button className={css.btn + ' greyBtn'} htmlType="submit">
                    Agree and Submit
                </Button>
                {/*<Button className={css.btn + ' greyBtn'} onClick={saveData}>*/}
                {/*    Save*/}
                {/*</Button>*/}
            </Form>
        </Col>
    );
});

export default RegisterAddInfo;
