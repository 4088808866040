// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_hjRbWe {\n  text-align: center;\n}\n.containBox_hjRbWe .listItem_f524S7 {\n  display: flex;\n  flex-wrap: wrap;\n  color: red;\n}\n.containBox_hjRbWe .bottomBtnBox_V7q3bi {\n  justify-content: space-between;\n}\n.containBox_hjRbWe .headTitle_dfX8Qf {\n  font-size: 22px;\n  font-weight: bold;\n  color: #920784;\n}\n.containBox_hjRbWe .headTitle1_u\\+gEHZ {\n  font-size: 20px;\n  font-weight: bold;\n  color: black;\n  background: #BBBBBB;\n  border-radius: 4px;\n  border-style: none;\n  text-align: center;\n  height: 40px;\n}\n.containBox_hjRbWe .headTitle2_BmGAzi {\n  font-size: 18px;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://src/pages/person/person.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,aAAA;EACA,eAAA;EACA,UAAA;AACJ;AAPA;EASI,8BAAA;AACJ;AAVA;EAaI,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAfA;EAmBI,eAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AADJ;AAzBA;EA8BI,eAAA;EACA,iBAAA;AAFJ","sourcesContent":[".containBox {\n  text-align: center;\n\n  .listItem {\n    display: flex;\n    flex-wrap: wrap;\n    color: red;\n  }\n  .bottomBtnBox{\n    justify-content: space-between;\n  }\n\n  .headTitle{\n    font-size: 22px;\n    font-weight: bold;\n    color: #920784;\n  }\n\n  .headTitle1{\n    font-size: 20px;\n    font-weight: bold;\n    color: black;\n    background: #BBBBBB;\n    border-radius: 4px;\n    border-style: none;\n    text-align:center;\n    height: 40px;\n  }\n\n  .headTitle2{\n    font-size: 18px;\n    font-weight: bold;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_hjRbWe",
	"listItem": "listItem_f524S7",
	"bottomBtnBox": "bottomBtnBox_V7q3bi",
	"headTitle": "headTitle_dfX8Qf",
	"headTitle1": "headTitle1_u+gEHZ",
	"headTitle2": "headTitle2_BmGAzi"
};
export default ___CSS_LOADER_EXPORT___;
