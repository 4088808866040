import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Col, Divider, Radio} from 'antd';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";

let store = require('src/store');
let data = store.edit_order;
let config = require('commonProject/config/config');
let api = require('src/api');
let moment = require('moment');
let history;
let commonUtils = require('commonProject/utils/Common');

function submit() {

}

function getOrder(id) {
    api.order.get_order_list({_id: id}).then(res => {
        if (res.code != 0 || res.data.length == 0) return;
        data.order = res.data.item[0];
    })
}

// create observer
let EditOrder = observer(function () {
    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    useEffect(() => {
        getOrder(params.id)
    }, [])

    if (commonUtils.isEmpty(data.order)) return <div/>
    let timeLength = moment.duration(data.order.subscribe_time_length, 'minutes');
    return (
        <Col>
            <p>Edit Your OPI/VRI Pre-booking</p>
            <Divider/>
            <div>You booked:
                <span>{data.order.interpret_lang_user_need.language_name + '-' + data.order.support_service_type.type_name}</span>
            </div>
            <div>Certification:
                <span>{data.order.certs.map(item => {
                    return (<span> {item.cert_desc}</span>);
                })}</span>
            </div>
            <p>Choose what to edit:</p>
            <span>If changing language, please go back and Cancel this pre-booking first, then start a new one.</span>
            <div>
                <Button type='primary' onClick={() => {
                    history.push('/change_book_time?id=' + data.order._id);
                }}>{moment(data.order.subscribe_time).format('MM/DD/YYYY dddd') + '(service date)'}</Button>
            </div>
            <p/>
            <div>
                <Button type='primary' onClick={() => {
                    history.push('/change_book_time?id=' + data.order._id);
                }}>{`${timeLength.hours()} hr ${timeLength.minutes()} min (service duration)`}</Button>
            </div>
            <p/>
            <div>
                <Button type='primary' onClick={() => {
                    history.push('/change_book_time?id=' + data.order._id);
                }}>{moment(data.order.subscribe_time).format('HH:mm:ss') + '(start time)'}</Button>
            </div>
        </Col>
    );
})

export default EditOrder;