import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Image, message, Radio, Row, TimePicker} from "antd";
import css from './default_week_work.module.less';
import {useHistory} from "react-router-dom";
import {useParams, useRouteMatch, useLocation} from "react-router";
import {loadStripe} from '@stripe/stripe-js';
import add_icon from 'src/assets/img/common/add_icon.svg';
import del_icon from 'src/assets/img/common/del_icon.svg';

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');


//设置默认工作时间页面

function initData() {
    try {
        console.log('userInfo', JSON.parse(JSON.stringify(store.persist.userInfo)));
        let user = JSON.parse(JSON.stringify(store.persist.userInfo));
        data.tempWorkTime = user.default_work_time ? JSON.parse(user.default_work_time) : {
            'monday': [], 'tuesday': [], 'wednesday': [], 'thursday': [], 'friday': [], 'saturday': [], 'sunday': []
        };
    } catch (e) {
        console.log(e.message)
    }
}

async function submit() {
    let params = {
        default_work_time: JSON.stringify(data.tempWorkTime),
    }
    let res = await api.interpreter.update_interpreter(params);
    if (res.code != 0) return;
    message.success('update interpreter default_work_time_success');
    await api.interpreter.get_interpreter_info();
    history.push('/person');
}

let Default_week_work = observer(function () {
    data = store.default_week_work;
    history = useHistory();
    useEffect(() => {
        // getOrders();
        initData();
    }, []);

    return (
        <Col className={css.containBox}>
            <p>Default week work</p>
            <p>Set your default work time for each week and it will start adding your future work time as soon as you
                are ready to work.
                <br/> The actual working time is set on the working time page</p>
            <div>{data.weeks.map(weekName => {
                let arr_work_time = data.tempWorkTime[weekName];
                console.log(arr_work_time, weekName, JSON.parse(JSON.stringify(data.tempWorkTime)))
                return (
                    <Row align='middle' className={css.itemBox}>
                        <Image src={add_icon} preview={false} onClick={() => {
                            console.log(arr_work_time);
                            arr_work_time.push([]);
                        }}/>
                        <span className={css.weekName}>{weekName}</span>
                        <div className={css.timePickBox}>
                            {commonUtils.isEmpty(arr_work_time) ? <div/> :
                                arr_work_time.map(item => {
                                    let startTime = item[0] ? moment(item[0], 'HH:mm') : null;
                                    let endTime = item[1] ? moment(item[1], 'HH:mm') : null;
                                    return (
                                        <div className={css.timePickMiddleBox}>
                                            <TimePicker.RangePicker defaultValue={[startTime, endTime]}
                                                                    className={css.timePicker}
                                                                    format={'hh:mm a'}
                                                                    use12Hours={true}
                                                                    minuteStep={5}
                                                                    key={Date.now()}
                                                                    onChange={arrTime => {
                                                                        if (!arrTime) return;
                                                                        let tempItem = [arrTime[0].format('HH:mm'), arrTime[1].format('HH:mm'),];
                                                                        arr_work_time[arr_work_time.indexOf(item)] = tempItem;
                                                                        console.log(arr_work_time);
                                                                    }}/>
                                            <Image src={del_icon} preview={false} onClick={() => {
                                                arr_work_time.remove(item);
                                            }}/>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </Row>
                )
            })}</div>
            <Row justify='center'>
                {/*<Button onClick={addNew} type='primary'>add new date</Button>*/}
                <Button onClick={submit} type='primary'>submit</Button>
            </Row>
        </Col>
    );
});

export default Default_week_work;