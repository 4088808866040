let mobx = require('mobx');
// create State object
let registerState = mobx.observable({
    formData: {
        user_name: '',
        chooseLangs: [],
        chooseCerts: [],
        work_years: 0,//工作年限
        short_bio: '',
    },
    chooseCerts: [],
    is_show_verify_ok: false,//是否显示验证成功的页面
    is_same_mail_address: false,//是否和邮寄地址一致
    choose_tz_now_time: 0,//选择的时区的当前时间
    timeZones: [ //目前只显示美国的几个时区
        {name: 'Atlantic Standard Time (Puerto Rico) / UTC-4', value: 'Atlantic/Stanley'},
        {name: 'Atlantic Daylight Time (Puerto Rico) / UTC-3', value: 'Atlantic/Stanley'},
        {name: 'Eastern Standard Time (New York) / UTC-5', value: 'America/New_York'},
        {name: 'Eastern Daylight Time (New York) / UTC-4', value: 'America/New_York'},
        {name: 'Central Standard Time (Chicago) / UTC-6', value: 'America/Chicago'},
        {name: 'Central Daylight Time (Chicago) / UTC-5', value: 'America/Chicago'},
        {name: 'Mountain Standard Time (Denver, Phoenix) / UTC-7', value: 'America/Denver'},
        {name: 'Mountain Daylight Time (Denver) / UTC-6', value: 'America/Denver'},
        {name: 'Pacific Standard Time (Los Angeles) / UTC-8', value: 'America/Los_Angeles'},
        {name: 'Pacific Daylight Time (Los Angeles) / UTC-7', value: 'America/Los_Angeles'},
        {name: 'Alaska Time (Anchorage) / UTC-9', value: 'America/Anchorage'},
        {name: 'Alaska Daylight Time (Anchorage) / UTC-8', value: 'America/Anchorage'},
        {name: 'Hawaii-Aleutian Standard Time (Honolulu) / UTC-10', value: 'Pacific/Honolulu'},
        {name: 'Hawaii-Aleutian Daylight Time (Honolulu) / UTC-9', value: 'Pacific/Honolulu'},
        {name: 'Samoa Standard Time (Pacific/Midway) / UTC-11', value: 'Pacific/Midway'},
        {name: 'Samoa Daylight Time (Pacific/Midway) / UTC-10', value: 'Pacific/Midway'},
        {name: 'Chamorro Standard Time (Pacific/Guam) / UTC+10', value: 'Pacific/Guam'},
    ],
    cities: [
        "AK  (Alaska)", "AL  (Alabama)", "AR  (Arkansas)", "AZ  (Arizona)", "CA  (California)", "CO  (Colorado)", "CT  (Connecticut)", "DC  (District of Columbia)", "DE  (Delaware)", "FL  (Florida)", "GA  (Georgia)", "GU  (Guam)", "HI  (Hawaii)", "IA  (Iowa)", "ID  (Idaho)", "IL  (Illinois)", "IN  (Indiana)", "KS  (Kansas)", "KY  (Kentucky)", "LA  (Louisiana)", "MA  (Massachusetts)", "MD  (Maryland)", "ME  (Maine)", "MH  (Marshall Islands)", "MI  (Michigan)", "MN  (Minnesota)", "MO  (Missouri)", "MS  (Mississippi)", "MT  (Montana)", "NC  (North Coralina)", "ND  (North Dakota)", "NE  (Nebraska)", "NH  (New Hampshire)", "NJ  (New Jersey)", "NM  (New Mexico)", "NV  (Nevada)", "NY  (New York)", "OH  (Ohio)", "OK  (Oklahoma)", "OR  (Oregon)", "PA  (Pennsylvania)", "PR  (Puerto Rico)", "PW  (Palau)", "RI  (Rhode Island)", "SC  (South Carolina)", "SD  (South Dakota)", "TN  (Tennessee)", "TX  (Texas)", "UT  (Utah)", "VA  (Virginia)", "VI  (Virgin Islands)", "VT  (Vermont)", "WA  (Washington)", "WI  (Wisconsin)", "WV  (West Virginia)", "WY  (Wyoming)"
    ],
});

// export default registerState;
module.exports = registerState;
