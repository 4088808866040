import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Checkbox, Col, message, Radio, Row} from "antd";
import css from './welcome.module.less';
import {useHistory} from "react-router-dom";
import {useParams, useRouteMatch, useLocation} from "react-router";
import {loadStripe} from '@stripe/stripe-js';

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');


// function

//welcome页面
let Welcome = observer(function () {
    data = store.welcome;
    history = useHistory();
    useEffect(() => {
        // getOrders();
    }, []);

    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>Welcome Login!</p>
            <p>1) Please try to plan your working hours for the next 30 days. 2) Try to be accurate so that the users
                can pre-book your service. 3) The better you plan the more bonus you get.
            </p>
            <p/>
            <div className={css.btnBox}>
                <Button className={'greyBtn ' + css.btnFun} onClick={() => {
                    history.push('/default_week_work')
                }}>Set default work week</Button>
                <Button className={'greyBtn bold ' + css.btnFun} onClick={() => {
                    history.push('/future_work_time')
                }}>Schedule next 30 days</Button>
                <span className={'hintGrayItalic'}>(This override the default work-week schedule)</span>
                <Button className={'greyBtn ' + css.btnFun} onClick={() => {
                    history.push('/future_work_time')
                }}>Schedule non-working days</Button>
                <span className={'hintGrayItalic'}>(This override the above two schedules)</span>

                <Button className={css.headTitle1 + ' ' + css.btnFun} type='primary' onClick={() => {
                    history.push('/home_login')
                }}>Next</Button>
            </div>

            {/*<p className={css.headTitle3}>fake, if already scheduled 3 days</p>*/}
            {/*<Checkbox className={css.headTitle2} onChange={(e) => {*/}
            {/*    store.persist.is_never_show_welcome = e.target.checked*/}
            {/*}}>Do not show this message again.</Checkbox>*/}
        </Col>
    );
});

export default Welcome;
