let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let user = {
    login(params) {
        return axios.post('/webapp/user/login', params);
    },
    register(params) {
        return axios.post('/webapp/user/register', params);
    },
    add_pay_subscribe(params) {
        return axios.post('/webapp/user/add_pay_subscribe', params);
    },
    recharge(params) {
        return axios.post('/webapp/user/recharge', params);
    },
    get_money_cut_of(params) {
        return axios.get(`/webapp/user/get_money_cut_of`,params);
    }
}

module.exports = user;
