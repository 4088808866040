import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Input, InputNumber, List, message, Radio, Row, Spin} from "antd";
import css from './cash_order_list.module.less';
import {useHistory} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

let mobx = require('mobx');
let data = mobx.observable({
    page: 1,
    pageSize: 10,
    loading: false,
    hasMore: true,
    cash_orders: [],
    selectItemOrder: null,
    is_show_add_cash_order: false,
    form_data: {
        money: 0,
        mark: '',
    }
});
let store = require('src/store');
let api = require('src/api');
let moment = require('moment');
let history;
let orderHelper = require('commonProject/helper/order_helper');

function get_cash_Orders() {
    let params = {
        page: data.page,
        pageSize: data.pageSize,
    }
    data.loading = true;
    api.cash_order.get_cash_order_list(params).then(res => {
        data.loading = false;
        if (res.code != 0) return;
        if (data.page == 1) {
            data.cash_orders = res.data.item;
        } else {
            let temp = JSON.parse(JSON.stringify(data.cash_orders));
            data.cash_orders = temp.concat(res.data.item);
        }
        console.log('data.cash_orders', data.cash_orders)

        data.page += 1;
        data.hasMore = res.data.total > data.cash_orders?.length;
    });
}

function getStatusStr(status) {
    if (status == '0') return 'failure';
    if (status == '1') return 'success';
    if (status == '2') return 'pending';
    if (status == '3') return 'rejected';
}

function add_cash_order() {
    api.cash_order.add_cash_order(data.form_data).then(res => {
        if (res.code != 0) return message.error(res.msg);
        data.is_show_add_cash_order = false;
        message.success('success');
        get_cash_Orders();
    });
}

function getListItemView(item) {
    /**
     * let CashOrderSchema = new Schema({
     *     money: {
     *         type: Number,
     *         default: 0,
     *     },
     *     rate: {//提现费率
     *         type: Number,
     *         default: 0.02,
     *     },
     *     real_money: {//真实到账金额
     *         type: Number,
     *         default: 0,
     *     },
     *     user: {type: Schema.Types.ObjectId, ref: 'user'},
     *     interpreter: {type: Schema.Types.ObjectId, ref: 'interpreter'},
     *     mark: String,
     *     //user_pay用户支付  user_subs_pay用户订阅支付  intp_withdraw口译师提现
     *     type: {
     *         type: String,
     *         default: 'intp_withdraw',
     *         enum: ['user_pay', 'user_subs_pay', 'intp_withdraw'],
     *     },
     *     status: {//提现状态 0失效 1提现成功 2提现审核中 3提现失败,被驳回
     *         type: Number,
     *         default: 2,
     *     },
     *     display: {
     *         type: String,
     *         default: 'inline',
     *     }
     * }, {
     *     collection: 'cash_order',
     *     timestamps: {createdAt: 'ctime', updatedAt: 'etime'},
     * })
     */
    return (
        <List.Item className={css.listItem}>
            <div>
                <div className={css.itemTitle}>Order number:{item._id}</div>
                <div className={css.itemTitle}>Amount of money:{item.money}</div>
                <div className={css.itemTitle}>Withdrawal rate:{item.rate}</div>
                <div className={css.itemTitle}>Real amount of money:{item.real_money}</div>
                <div className={css.itemTitle}>Withdrawal status:{getStatusStr(item.status)}</div>
                <div className={css.itemTitle}>Withdrawal time:{moment(item.ctime).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div className={css.itemTitle}>Withdrawal type:{item.type}</div>
                <div className={css.itemTitle}>Withdrawal remarks:{item.mark}</div>
            </div>
        </List.Item>
    )
}

const loadMore =
    !data.loading && data.hasMore ? (
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button onClick={get_cash_Orders}>loading more</Button>
        </div>
    ) : null;

function initData() {
    let userInfo = store.persist.userInfo;
    //跳到填写用户信息页面
    // if (!userInfo.email || !userInfo.phone_num) return history.push('/register_is_admin');
    get_cash_Orders();
    api.interpreter.get_interpreter_info();
}

// create observer
let CashOrderList = observer(function () {
    history = useHistory();
    useEffect(() => {
        initData();
    }, []);

    return (
        <Col className={css.containBox}>
            <Col align='middle'>
            </Col>
            <p className={css.loginDesc}>Manage cash orders</p>
            <p className={css.btnBox1}>(scroll up or down to view):</p>
            <Button className={css.btnBox} onClick={() => {
                data.is_show_add_cash_order = true;
            }}>Create cash order</Button>
            {/*总金额*/}
            <div className={css.btnBox1}>all amount of money:{store.persist.userInfo.all_money}</div>
            {/*    创建cash order*/}
            {
                data.is_show_add_cash_order ? (
                    <div>
                        <p className={css.btnBox1}>Create cash order:</p>
                        <div className={css.formBox}>
                            <div className={css.formItem}>
                                <span className={css.formItemTitle}>Amount of money:</span>
                                <InputNumber
                                    className={css.formItemInput}
                                    value={data.form_data.money}
                                    onChange={(value) => {
                                        data.form_data.money = value;
                                    }}
                                />
                            </div>
                            <div className={css.formItem}>
                                <span className={css.formItemTitle}>Withdrawal remarks:</span>
                                <Input
                                    className={css.formItemInput}
                                    value={data.form_data.mark}
                                    onChange={(e) => {
                                        data.form_data.mark = e.target.value;
                                    }}
                                />
                            </div>
                        </div>
                        <Button className={css.btnBox} onClick={() => {
                            add_cash_order();
                        }}>Submit</Button>
                    </div>
                ) : null
            }
            <Radio.Group onChange={(e) => {
                data.selectItemOrder = e.target.value;
            }}>
                <List
                    className='demo-loadmore-list'
                    loading={data.loading}
                    loadMore={loadMore}
                    dataSource={data.cash_orders}
                    itemLayout='vertical'
                    renderItem={item => (
                        getListItemView(item)
                    )}/>
            </Radio.Group>
        </Col>
    );
});

export default CashOrderList;
