import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, message, Radio, Row, Select} from "antd";
import css from './person.module.less';
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {loadStripe} from '@stripe/stripe-js';

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let orderHelper = require('commonProject/helper/order_helper');
let config = require('commonProject/config/config')
const stripePromise = loadStripe(config.stripe.publicKey);


//个人页面


async function initData() {
    data.intp_status = store.persist.userInfo.status;
    data.intp_work_status = store.persist.userInfo.work_status;
}

async function checkoutPay() {
    // Get Stripe.js instance
    let stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    let result = await api.user.recharge();
    if (result.code != 0) return;

    let pay_order = result.data;

    // When the customer clicks on the button, redirect them to Checkout.
    let payResult = await stripe.redirectToCheckout({
        sessionId: pay_order.order_id,
    });
}

//更改口译师的账号状态和工作状态
async function confirmChangeStatus() {
    let params = {
        status: Number(data.intp_status),
        work_status: Number(data.intp_work_status),
    }
    let res = await api.interpreter.update_interpreter(params);
    if (res.code != 0) return;
    message.success('update success');
}

//获取口译师状态名字
function getIntpStatusName(status) {
    for (let item of data.arr_intp_status) {
        if (item.status == status) {
            return item.status_name;
        }
    }
    return '';
}

//获取口译师工作状态名字
function getIntpWorkStatusName(status) {
    for (let item of data.arr_intp_work_status) {
        if (item.status == status) {
            return item.status_name;
        }
    }
    return '';
}

// create observer
let Person = observer(function () {
    data = store.person;
    history = useHistory();
    useEffect(() => {
        // getOrders();
        initData();
    }, []);


    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>My Page</p>
            <Button className={css.headTitle1} onClick={() => {
                history.push('/default_week_work');
            }} type='primary'>Set my default week work</Button>
            <p/>
            <span className={css.headTitle2}>status: </span>
            <Select defaultValue={getIntpStatusName(store.persist.userInfo.status)}
                    placeholder={'set your account status'}
                    onChange={(value) => {
                        data.intp_status = value
                    }}>
                {data.arr_intp_status.map(item => {
                    return (
                        <Select.Option value={item.status} disabled={!item.allow_change}>
                            {item.status_name}
                        </Select.Option>
                    )
                })}
            </Select>
            <p/>
            <span className={css.headTitle2}>work status: </span>
            <Select defaultValue={getIntpWorkStatusName(store.persist.userInfo.work_status)}
                    placeholder={'set your work status'} onChange={(value) => {
                data.intp_work_status = value
            }}>
                {data.arr_intp_work_status.map(item => {
                    return (
                        <Select.Option value={item.status} disabled={!item.allow_change}>
                            {item.status_name}
                        </Select.Option>
                    )
                })}
            </Select>
            <p/>

            <Button className={css.headTitle1} onClick={confirmChangeStatus}>confirm change status</Button>
            <p/>
            <Button className={css.headTitle1} onClick={() => {
                history.push('/future_work_time');
            }} type='primary'>Set future work time</Button>
            <p/>
            <Button className={css.headTitle1} role="link" type='primary' onClick={checkoutPay}>
                Checkout Pay
            </Button>
        </Col>
    );
});

export default Person;