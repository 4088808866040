import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Divider, Image, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './home.module.less';
import {useLocation} from "react-router";

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

//获取不同类型的布局
function getTypeView(type) {
    if (!type) {
        return (
            <div style={{width: '100%'}}>
                {/*<div className={css.signBox}>*/}
                {/*    <p className={css.leftTitle}>User</p>*/}
                {/*    <div className={css.rightBox}>*/}
                {/*        <Button className={`greyBtn ${css.btn}`} onClick={() => {*/}
                {/*            window.location.href = config.url.user_client_url + '/login/user';*/}
                {/*        }}>Log In</Button>*/}
                {/*        <Button className={`${css.btnSignUp}`} onClick={() => {*/}
                {/*            window.location.href = config.url.user_client_url + '/register/register_phone';*/}
                {/*        }}>Sign Up</Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<Divider className={'gray'}/>*/}

                <div className={css.signBox}>
                    <p className={css.leftTitleIntp}>Interpreter</p>
                    <div className={css.rightBox}>
                        <Button className={`greyBtn ${css.btn}`} onClick={() => {
                            window.location.href = config.url.interpreter_client_url + '/login/interpreter';
                        }}>Log In</Button>
                        <Button className={`${css.btnSignUp}`} onClick={() => {
                            window.location.href = config.url.interpreter_client_url + '/register/register_phone';
                        }}>Sign Up</Button>
                    </div>
                </div>
            </div>
        )
    } else if (type == 'providerTown') {
        return (
            <div className={css.providerTown}>
                <p className={css.desc}>For small to medium businesses</p>
                <p className={css.desc}>Currently we provide Spanish only</p>
                <p className={css.desc}>User registration by invitation only</p>
                <Button className={`greyBtn ${css.homeBtn}`} onClick={() => {
                    history.push('/')
                }}>Home Page</Button>
            </div>
        )
    } else if (type == 'contactUs') {
        return (
            <div className={css.contactUs}>
                <p className={css.desc}>Please email us and we will get back to you within 24 hours.</p>
                <p className={css.contact}>ContactUs@InterpreTown.com</p>
                <Button className={`greyBtn ${css.homeBtn}`} onClick={() => {
                    history.push('/')
                }}>Home Page</Button>
            </div>
        )
    }
}

// create observer
let Home = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
    }, []);

    return (
        <div className={css.containBox}>
            <Image src={intp_logo} className={css.logo}/>
            <h1 className={css.title}>building mutual trust</h1>
            <Divider className={css.title5+ ' gray'}/>
            {getTypeView(type)}
        </div>
    );
})
export default Home;
