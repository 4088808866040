import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Card, Checkbox, Col, Divider, Image, List, message, Radio, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";

let store = require('src/store');
let config = require('commonProject/config/config');
let api = require('src/api');

function getCerts() {
    api.order.get_cert_list().then(res => {
        store.add_order.chooseCerts = res.data;
    })
}

// create observer
let ChooseCert = observer(function () {
    let history = useHistory();
    let formData = store.persist.add_order.formData;

    useEffect(() => {
        getCerts();
    }, [])

    return (
        <Col>
            <p>step 2 of 4</p>
            <p>Specialty certificate</p>
            <Divider/>
            <p>{`Language Chosen:  ${formData.chooseLang?.language_name}`}</p>
            <Divider/>
            <p>Choose a specialty certificate if required, or skip:</p>
            <Checkbox.Group onChange={arr => {
                formData.chooseCerts = arr;
            }}>
                {
                    store.add_order.chooseCerts.map(item => {
                        return (
                            <Checkbox value={item}>{`${item.cert_desc}(${item.cert_name})`}</Checkbox>
                        )
                    })
                }
            </Checkbox.Group>
            <Col offset={9}>
                <Button type='primary' onClick={e => {
                    history.push('/add_order/choose_support_service');
                }}>{formData.chooseCerts.length > 0 ? 'Sure' : 'Skip'}</Button>
            </Col>
            <p/>
        </Col>
    );
})

export default ChooseCert;