let mobx = require('mobx');
// create State object
let data = {
    dataAll: {},//首页总数据
    order: {},//分配到的订单
    is_show_ready_dialog: false,
    work_status: '',//用户状态
    long_connect_status: '',//长连接状态 connected表示已经连接，disconnected表示未连接
    yesterday_columns: [],
    yesterday_data: [],
    is_show_yesterday_dialog: false,
    is_show_local_time: true,
}
let homeLoginState = mobx.observable(data);

// export default loginState;
module.exports = homeLoginState;
