// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn_3cfQWw {\n  margin-top: 10px;\n  background: #e4e4e4 100%;\n  color: black;\n  font-size: 18px;\n  height: 40px;\n  border-radius: 6px;\n  padding: 5px 20px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/component/back_btn/back_btn.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,wBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AACF","sourcesContent":[".btn{\n  margin-top: 10px;\n  background: rgb(228, 228, 228) 100%;\n  color: black;\n  font-size: 18px;\n  height: 40px;\n  border-radius: 6px;\n  padding: 5px 20px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "btn_3cfQWw"
};
export default ___CSS_LOADER_EXPORT___;
