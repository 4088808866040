import React, {useEffect, useRef} from 'react';
import {observer} from "mobx-react";
import {Button, Col, Divider, Input, message, Modal, Row, Select} from "antd";
import css from './order_active.module.less';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {Option} from "antd/es/mentions";
import homeLoginCss from "../home_login/home_login.module.less";
import ReactAudioPlayer from "react-audio-player";
import UserEndedMeeting from 'src/assets/voice/UserEndedMeeting.mp3';
import UserSideVideoMeetingLink from 'src/assets/voice/UserSideVideoMeetingLink.mp3';

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let agoraHelper = require('commonProject/helper/AgoraHelper');
let websocketHelper = require('commonProject/helper/websocket_helper');
let orderHelper = require('commonProject/helper/order_helper');
let freshdeskHelper = require('commonProject/helper/freshdesk_helper');

let roleType;//自身角色类型
let preeRoleType;//对方角色类型
let peerId;
let divRef;
let time_zone;
let refAudioUserEndedMeeting;
let refAudioUserSideVideoMeetingLink;

async function getOrder(id) {
    let res = await api.order.get_order_list({_id: id});
    if (res.code != 0 || res.data.length == 0) return;
    data.order = res.data.item[0];
}

async function onClickBottomBtn(type) {
    let status;
    if (type == 'start') {
        status = roleType == 'user' ? 5 : 4;
        let isUpdateOk = await updateOrderStatus(status);
        if (isUpdateOk) data.recordList.push('You confirm started. ');
    } else if (type == 'pause') {
        let isUpdateOk = await updateOrderStatus(9);
        if (isUpdateOk) data.recordList.push(`You paused,It doesn't count time`);
    } else if (type == 'resume') {
        let isUpdateOk = await updateOrderStatus(3);
        if (isUpdateOk) data.recordList.push(`You resume,continue count time`);
    } else if (type == 'end') {
        Modal.confirm({
            content: 'are you sure confirm end order',
            onOk: async function () {
                status = roleType == 'user' ? 7 : 6;
                let oldOrderStatus = data.order.status;
                let isUpdateOk = await updateOrderStatus(status);
                //更新好了  如果订单状态不为7 即用户没点订单结束，就加一句等待
                if (isUpdateOk) data.recordList.push(`You confirm end${oldOrderStatus != 7 ? '' : ''}`);
            },
        })
    } else if (type == 'next') {
        history.push(`/order_evaluate?id=${data.order._id}`)
    }
}

async function onComponentUnmount() {
    agoraHelper.logout();
}

//发送消息时的回调
async function onClickSendMsg() {
    // let resSend = await agoraHelper.sendMessageToPeer(data.inputTxt, peerId);
    // if (!resSend.hasPeerReceived) return message.error('send message failed');
    let res = await api.order.send_msg_to({_id: peerId, role_type: preeRoleType, msg: data.inputTxt});
    if (res.code != 0) return message.error('send message failed');
    data.recordList.push('You: ' + data.inputTxt);
    scrollToBottom();
}

//获取暂停和继续的字符串
function getPauseOrResumeMsg() {
    //3状态是订单执行中，9是订单暂停中。
    if (data.order.status == 3) return 'pause';
    if (data.order.status == 9) return 'resume';
    return 'pause';
}

//更新订单状态
async function updateOrderStatus(status) {
    let res = await api.order.update_order({_id: data.order._id, status: status});
    if (res.code != 0) return false;
    //重新获取订单数据
    await getOrder(data.order._id);
    let resSendOrderChangeToOther = await api.order.send_msg_to({
        _id: peerId, role_type: preeRoleType, msg: {
            id_order: data.order._id, status: status,
        },
        action_type: config.websocket.action_type.receive_order_change
    });
    return true;
}

//接收到聊天消息的回调
async function cb_receive_chat_msg(strMsg) {
    if (strMsg == 'I will using my own video meeting to invite you join in.') {
        data.recordList.push(
            <div className={css.redInfo}>
                {preeRoleType + ': ' + strMsg}
            </div>
        );
        refAudioUserSideVideoMeetingLink.current.audioEl.current.play();
    } else {
        data.recordList.push(preeRoleType + ': ' + strMsg);
    }

    scrollToBottom();
}

//订单状态改变的回调
async function cb_receive_order_change(order) {
    // data.recordList.push(`order change:${preeRoleType}:${order.status}:${orderHelper.getStatusName(order.status)}`);
    data.recordList.push(`${orderHelper.getStatusDesc(order.status)}`);
    // 如果监听到订单状态更改为7，即用户确认结束，就播放结束音乐UserEndedMeeting.mp3
    if (order.status == 7) {
        refAudioUserEndedMeeting.current.audioEl.current.play();
    }
    getOrder(data.order._id);
}

async function initData(order_id) {
    await getOrder(order_id);
    if (commonUtils.isEmpty(data.order)) return;
    if (data.order.status == 2) {
        data.recordList.push('Please press "Confirm Start" below to start the service.');
    } else data.recordList.push(`order status is ${orderHelper.getStatusName(data.order.status)}`);

    //把这个链接https://us06web.zoom.us/j/6185369687?pwd=ajlqUzZUNDFVKzVlWmJlS1JaUWtxQT09 取出生成 618-536-9687
    let zoomMeetingId = data.order.interpreter.zoom_link?.split('j/')[1]?.split('?')?.[0]?.replace(/-/g, '');
    //zoomMeetingId目前是6185369687需要变成618-536-9687
    zoomMeetingId = zoomMeetingId?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

    data.recordList.push(
        <div className={'lightBlue'}>
            To call-in your Zoom meeting, ask customer or LEP to dial <span className={'bold'}>(602)753-0140</span>,
            then dial your meeting ID {zoomMeetingId} followed by # # (two pound signs)
        </div>
    );

    agoraHelper.login(store.persist.userInfo._id);
    agoraHelper.on('MessageFromPeer', ({text}, peerId) => {
        data.recordList.push('other:' + text);
    })
    peerId = preeRoleType == 'user' ? data.order.user._id : data.order.interpreter._id;

    websocketHelper.cb_receive_chat_msg = cb_receive_chat_msg;
    websocketHelper.cb_receive_order_change = cb_receive_order_change;
    websocketHelper.initWebsocket({...store.persist.userInfo, token: store.persist.token});
}

const scrollToBottom = () => {
    divRef.current.scrollTop = divRef.current.scrollHeight - divRef.current.clientHeight;
};

// 订单正在执行页面
let Order_active = observer(function () {
    history = useHistory();
    data = store.order_active;
    time_zone = commonUtils.getClientTimeZone();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let order_id = params.id;
    roleType = store.persist.userInfo.role_type;
    preeRoleType = roleType == 'user' ? 'interpreter' : 'user';
    divRef = useRef(null);
    refAudioUserEndedMeeting = useRef(null);
    refAudioUserSideVideoMeetingLink = useRef(null);
    useEffect(async () => {
        await initData(order_id);
        freshdeskHelper.hideWidget();
        return onComponentUnmount;//useEffect return一个方法对象，会在组件被销毁的时候调用
    }, []);

    if (commonUtils.isEmpty(data.order)) return <div/>
    let order = data.order;
    let user = data.order.user;
    if (roleType == 'user') {
        return (
            <Col className={css.containBox}>
                <div>{`You chose: ${data.order.interpret_lang_user_need.language_name}-${data.order.support_service_type.type_name}`}</div>
                <div>{`Your callback number: ${data.order.contact.phone_num} (${data.order.contact.contact_nick_name})`}</div>
                <div>{`Interpreter ID: ${data.order.interpreter._id}  ${data.order.interpreter.user_name}`}</div>
                <div>{`Starting time: ${moment.utc(data.order.real_start_time).local().format('MM/DD/YYYY HH:mm dddd')}.`}</div>
                <Divider style={{margin: '10px 0'}}/>
                <div className={'bold'}>Event Recoding:</div>
                {data.recordList.map(item => {
                    if (typeof item === 'string') {
                        return <div>{item}</div>;
                    } else if (React.isValidElement(item)) {
                        // 如果 item 是一个 React 元素，直接渲染
                        return item;
                    } else {
                        // 在这里处理其他情况，根据需要返回不同的渲染结果
                        return null;
                    }
                })}
                <Row className={css.sendBox}>
                    <Input className={css.inputTxt} onChange={e => {
                        data.inputTxt = e.target.value;
                    }}/>
                    <Button type='primary' disabled={commonUtils.isEmpty(data.inputTxt)}
                            onClick={onClickSendMsg}>send</Button>
                </Row>
                <Row className={css.bottomBtnBox}>
                    <Button disabled={![2, 4].includes(order.status)}
                            onClick={() => onClickBottomBtn('start')}
                            type='primary'>Confirm Start</Button>
                    <Button disabled={![3, 9].includes(order.status)}
                            onClick={() => onClickBottomBtn(getPauseOrResumeMsg())}
                            type='primary'>{getPauseOrResumeMsg()}</Button>
                    <Button disabled={![3, 6].includes(order.status)} onClick={() => onClickBottomBtn('end')}
                            type='primary'>Confirm End</Button>
                    <Button disabled={order.status != 8} onClick={() => onClickBottomBtn('next')}
                            type='primary'>Next</Button>
                </Row>
            </Col>
        );
    } else if (roleType == 'interpreter') {
        return (
            <Col className={css.containBox}>

                <ReactAudioPlayer src={UserEndedMeeting}
                    // autoPlay
                                  ref={refAudioUserEndedMeeting}
                                  style={{display: 'none'}}
                                  controls/>

                <ReactAudioPlayer src={UserSideVideoMeetingLink}
                    // autoPlay
                                  ref={refAudioUserSideVideoMeetingLink}
                                  style={{display: 'none'}}
                                  controls/>
                <div ref={divRef} className={css.recordListBox}>
                    <div>User: {user.first_name} (Language: {order.interpret_lang_user_need.language_name})</div>
                    <div>{order.user.company.company_name}</div>
                    <div>{`Starting time: ${moment.utc(data.order.real_start_time).local().format('MM/DD/YYYY HH:mm dddd')}.`}</div>
                    <Divider style={{margin: '10px 0'}}/>
                    <div className={'bold'}>Event Recoding:</div>
                    <div className={css.recordHintRed}>Press "Confirm Start", then immediately tell the customer "Hello,
                        please press the Confirm Start on your InterpreTown webpage, so that I can start the service".
                    </div>
                    <div className={css.recordHintBlue}>Before you hang up when finish, press the Confirm End button,
                        and
                        immediately tell the customer "Before you hang up, please press the Confirm End button on your
                        InterpreTown webpage, Thank you".
                    </div>
                    {data.recordList.map(item => {
                        if (typeof item === 'string') {
                            return <div dangerouslySetInnerHTML={{__html: commonUtils.urlToLink(item)}}/>
                        } else if (React.isValidElement(item)) {
                            // 如果 item 是一个 React 元素，直接渲染
                            return item;
                        } else {
                            // 在这里处理其他情况，根据需要返回不同的渲染结果
                            return null;
                        }
                    })}
                </div>
                <Row className={css.sendBox}>
                    <Input className={css.inputTxt} onChange={e => {
                        data.inputTxt = e.target.value;
                    }}/>
                    <Button type='primary' disabled={commonUtils.isEmpty(data.inputTxt)}
                            onClick={onClickSendMsg}>send</Button>
                </Row>
                <Row className={css.bottomBtnBox}>
                    <Button
                        disabled={![2, 3, 5].includes(order.status) || order.obj_status_update_at.intp_start_order_at}
                        onClick={() => onClickBottomBtn('start')}
                        type='primary'>Confirm Start</Button>
                    {/*<Button disabled={![3, 9].includes(order.status)}*/}
                    {/*        onClick={() => onClickBottomBtn(getPauseOrResumeMsg())}*/}
                    {/*        type='primary'>{getPauseOrResumeMsg()}</Button>*/}
                    <Button
                        disabled={order.obj_status_update_at.intp_end_order_at}
                        onClick={() => onClickBottomBtn('end')}
                        type='primary'>Confirm End</Button>
                    <Button disabled={![6, 8].includes(order.status)} onClick={() => onClickBottomBtn('next')}
                            type='primary'>Next</Button>
                </Row>
            </Col>
        );
    }
    return (
        <div/>
    );
})

export default Order_active;
