import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Col, DatePicker, Divider, Image, message, Modal, Row, TimePicker} from "antd";
import css from './future_work_time.module.less';
import {useHistory} from "react-router-dom";
import add_icon from 'src/assets/img/common/add_icon.svg';
import del_icon from 'src/assets/img/common/del_icon.svg';
// import DatePicker from "react-datepicker";

let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');


//未来工作时间设置页面


async function initData() {
    let res = await api.interpreter.get_interpreter_work_time();
    if (res.code != 0) return;
    data.arr_old_work_time = res.data;
    // data.arr_new_work_time.concat(JSON.parse(JSON.stringify(data.arr_old_work_time)));
    data.arr_new_work_time = JSON.parse(JSON.stringify(data.arr_old_work_time));
}

//添加新的工作时间
function addNew() {
    let workTimeItem = {
        work_date: moment(),
        work_today_time: [[]],
    }
    data.arr_new_work_time.unshift(workTimeItem);
    console.log(data.arr_new_work_time);
}

//提交保存工作时间
async function saveWorkTime() {
    let addArr = [];
    let delArr = [];
    let updateArr = [];
    data.arr_new_work_time.forEach(new_work_time => {
        if (!new_work_time._id) {
            addArr.push(new_work_time);
        }
    })

    data.arr_old_work_time.forEach(old_work_time => {
        let isNewContainOld = false;//新的数组里面是否包含旧的这个数据，没有的话就表示被删除了；
        data.arr_new_work_time.forEach(new_work_time => {
            if (!new_work_time._id) return;
            if (new_work_time._id == old_work_time._id) {
                isNewContainOld = true;
                if (JSON.stringify(new_work_time.work_today_time) != JSON.stringify(old_work_time.work_today_time)) {
                    updateArr.push(new_work_time);
                }
            }
        })
        if (!isNewContainOld) delArr.push(old_work_time);
    })

    let delP = [];
    delArr.forEach(item => {
        let p = api.interpreter.del_interpreter_work_time({_id: item._id});
        delP.push(p);
    })
    let resDel = await Promise.allSettled(delP);

    let resAdd;
    if (addArr.length > 0) {
        resAdd = await api.interpreter.add_interpreter_work_time({work_times: addArr});
    }

    let updateP = [];
    updateArr.forEach(item => {
        let p = api.interpreter.update_interpreter_work_time(item);
        updateP.push(p);
    })
    let resUpdate = await Promise.allSettled(updateP);
    for (let updateItem of resUpdate) {
        if (updateItem.code != 0) {
            return;
        }
    }

    message.success('save success');
    console.log(resDel, resAdd, resUpdate);
    history.go(0);
}

let Future_work_time = observer(function () {
    data = store.future_work_time;
    history = useHistory();
    useEffect(() => {
        initData();
    }, []);
    let arr_work_time = data.arr_new_work_time;
    return (
        <Col className={css.containBox}>
            <p className={css.headTitle}>Schedule Next 30 Days</p>
            <p className={css.headTitle1}>Try to be accurate because customers will pre-book your service according to your scheduled working time.</p>
            <Divider/>
            <p className={css.headTitle2}>Enter/Edit Your Schedule:</p>
            <div className={css.listTitle}>
                <span className={css.date}>Date</span>
                <span className={css.from}>From</span>
                <span className={css.to}>To</span>
            </div>
            <div className={css.listBox}>
                {arr_work_time.map(item => {
                    return (
                        <Row align='middle' className={css.itemBox}>
                            <Image src={add_icon} preview={false} onClick={() => {
                                item.work_today_time.push([null, null]);
                            }}/>
                            {
                                item._id ?
                                    <span className={css.weekName}>{moment(item.work_date).format('MM/DD ddd')}</span>
                                    : <DatePicker defaultValue={moment(item.work_date)}
                                                  disabledDate={commonUtils.disabledDate}
                                                  style={{width: '120px'}}
                                                  suffixIcon={null}
                                                  onChange={
                                                      date => {
                                                          item.work_date = date;
                                                      }
                                                  }/>
                            }
                            <div className={css.timePickBox}>
                                {
                                    item.work_today_time.map((t_time, index) => {
                                        let startTime = null;
                                        let endTime = null;
                                        if (t_time[0]) startTime = moment(t_time[0], 'HH:mm');
                                        if (t_time[1]) endTime = moment(t_time[1], 'HH:mm');
                                        return (
                                            <div className={css.timePickMiddleBox}>
                                                <TimePicker.RangePicker defaultValue={[startTime, endTime]}
                                                                        className={css.timePicker}
                                                                        format={'hh:mm a'}
                                                                        use12Hours={true}
                                                                        minuteStep={5}
                                                                        key={Date.now()}
                                                                        onChange={arrTime => {
                                                                            t_time = [arrTime[0].format('HH:mm'), arrTime[1].format('HH:mm'),];
                                                                            item.work_today_time[index] = t_time;
                                                                            console.log(t_time, item);
                                                                        }}/>
                                                {/*<p>{startTime.format('HH:mm') + '  ' + endTime.format('HH:mm')}</p>*/}
                                                <Image src={del_icon} preview={false} onClick={() => {
                                                    item.work_today_time.remove(t_time);
                                                    if (item.work_today_time.length == 0) arr_work_time.remove(item);
                                                }}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Row>
                    )
                })}
            </div>
            <Row className={css.bottomBtnBox}>
                <Button className={css.headTitle3} onClick={addNew} type='primary'>Add date</Button>
                <Button className={css.headTitle3} onClick={saveWorkTime} type='primary'>Save</Button>
                <Button className={css.headTitle3} onClick={() => {
                    history.push('/home_login')
                }} type='primary'>Ready to work</Button>
            </Row>
        </Col>
    );
});

export default Future_work_time;
