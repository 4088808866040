// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_C9G4Pp .listItem_YsB2DG {\n  display: flex;\n  flex-wrap: wrap;\n  color: red;\n}\n.containBox_C9G4Pp .bottomBtnBox_ARo\\+kI {\n  justify-content: space-between;\n}\n.containBox1_CLQpWq {\n  display: flex;\n  background-color: blue;\n}\n.containBox1_CLQpWq .box2_1cJehD {\n  background: red;\n}\n.containBox1_CLQpWq .box2_1cJehD .box3_9SNytF {\n  background: green;\n}\n.containBox1_CLQpWq .box3_9SNytF {\n  background: yellow;\n}\n", "",{"version":3,"sources":["webpack://src/pages/pay_result/pay_result.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;EACA,UAAA;AAAJ;AAJA;EAOI,8BAAA;AAAJ;AAIA;EACE,aAAA;EAEA,sBAAA;AAHF;AAAA;EAMI,eAAA;AAHJ;AAHA;EASM,iBAAA;AAHN;AANA;EAcI,kBAAA;AALJ","sourcesContent":[".containBox {\n  .listItem {\n    display: flex;\n    flex-wrap: wrap;\n    color: red;\n  }\n  .bottomBtnBox{\n    justify-content: space-between;\n  }\n}\n\n.containBox1 {\n  display: flex;\n  //flex-direction: column;\n  background-color: blue;\n\n  .box2 {\n    background: red;\n\n    .box3 {\n      background: green;\n    }\n  }\n\n  .box3 {\n    background: yellow;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_C9G4Pp",
	"listItem": "listItem_YsB2DG",
	"bottomBtnBox": "bottomBtnBox_ARo+kI",
	"containBox1": "containBox1_CLQpWq",
	"box2": "box2_1cJehD",
	"box3": "box3_9SNytF"
};
export default ___CSS_LOADER_EXPORT___;
