// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containBox_F9foYJ .disabled_6vkwPI {\n  /* for \"disabled\" effect */\n  opacity: 0.5;\n  background: #CCC;\n}\n.containBox_F9foYJ .radioText_h0N18x {\n  color: #122b88;\n  font-size: 20px;\n}\n.containBox_F9foYJ .readyCallBtn_ZFXduk {\n  margin: 30px 0 160px 0;\n}\n.containBox_F9foYJ .searchId2Box_-FQI4K {\n  display: flex;\n  align-items: center;\n}\n.containBox_F9foYJ .searchId2Box_-FQI4K .desc_FsDnRj {\n  flex-grow: 1;\n  white-space: nowrap;\n}\n.containBox_F9foYJ .searchId2Box_-FQI4K .inputId2_Q\\+StPs {\n  flex-grow: 1;\n}\n.containBox_F9foYJ .readyCallBtn2_CMIiFb {\n  margin: 10px 0 0 0;\n}\n", "",{"version":3,"sources":["webpack://src/pages/money_cut_of/money_cut_of.module.less"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAOxB,YAAA;EACA,gBAAA;AALJ;AAJA;EAaI,cAAA;EACA,eAAA;AANJ;AARA;EAkBI,sBAAA;AAPJ;AAXA;EAsBI,aAAA;EACA,mBAAA;AARJ;AAfA;EA0BM,YAAA;EACA,mBAAA;AARN;AAnBA;EA+BM,YAAA;AATN;AAtBA;EAqCI,kBAAA;AAZJ","sourcesContent":[".containBox {\n  //text-align: center;\n\n\n  .disabled {\n    //pointer-events: none;\n\n    /* for \"disabled\" effect */\n    opacity: 0.5;\n    background: #CCC;\n  }\n\n  .radioText {\n    color: rgba(18, 43, 136, 100);\n    font-size: 20px;\n  }\n\n  .readyCallBtn {\n    margin: 30px 0 160px 0;\n  }\n\n  .searchId2Box{\n    display: flex;\n    align-items: center;\n\n    .desc{\n      flex-grow: 1;\n      white-space: nowrap;\n    }\n    .inputId2{\n      //width: 60px;\n      flex-grow: 1;\n    }\n  }\n\n\n  .readyCallBtn2 {\n    margin: 10px 0 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containBox": "containBox_F9foYJ",
	"disabled": "disabled_6vkwPI",
	"radioText": "radioText_h0N18x",
	"readyCallBtn": "readyCallBtn_ZFXduk",
	"searchId2Box": "searchId2Box_-FQI4K",
	"desc": "desc_FsDnRj",
	"inputId2": "inputId2_Q+StPs",
	"readyCallBtn2": "readyCallBtn2_CMIiFb"
};
export default ___CSS_LOADER_EXPORT___;
