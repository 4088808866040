import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Button, Col, Input, Row, message, Form, Radio, Select, Checkbox, Divider, Modal, Image} from "antd";
import {Link} from "react-router-dom";
import css from "./register_phone.module.less";
import CountDown from "ant-design-pro/lib/CountDown";
import right_svg from 'src/assets/img/common/right.svg';

let store = require('../../../store');
let api = require('src/api');
let data = store.persist.register;
// eslint-disable-next-line react-hooks/rules-of-hooks
let history;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');

//发送手机验证
async function verify_phone_send_code(is_voice = false) {
    let phone_num = data.phone_num;
    if (commonUtils.isEmpty(phone_num)) return message.error('phone num is empty');
    let res = await api.interpreter.verify_phone_send_code({phone_num: phone_num, is_verify_new: true, is_voice});
    if (res.code != 0) return;
    message.success('send code success');
    data.phone_code_time = new Date().getTime() + commonUtils.config.valid_code_time;

}

async function verify_phone() {
    let phone_num = data.phone_num;
    if (commonUtils.isEmpty(phone_num)) return message.error('phone num is empty');
    let res = await api.interpreter.verify_phone({phone_num: phone_num, phone_code: data.phone_code});
    if (res.code != 0) return;
    let resResult = await register();
    if (!resResult) return
    store.register.is_show_verify_ok = true;
}

async function register() {
    let res = await api.interpreter.register(data);
    if (res.code != 0) return false;
    message.success(res.msg);
    return true;
}

function initData() {
    // data = store.persist.register;
}

// create observer
let RegisterEmail = observer(function () {
    history = useHistory();
    useEffect(() => {
        initData();
    }, []);

    if (commonUtils.isEmpty(data)) return <div/>;
    return (
        <Col className={css.containBox}>
            <p className={css.headTitleBox + ' wineRed'}>Interpreter Sign Up</p>
            <span className={css.loginDesc + ' wineRed'}>Sign up with cell phone number:</span>
            <Input placeholder='(602)-000-1234' defaultValue={data.phone_num} onChange={(e) => {
                data.phone_num = e.target.value
            }}/>
            <p className={css.hint + ' hintGray'}>We'll text or call you for the sign in code.</p>
            <div className={css.phoneCodeBox} align='middle'>
                <p className={css.loginDesc + ' wineRed'}>Invitation Code:</p>
                <Input placeholder='invitation code'
                       className={css.phoneCode}
                       defaultValue={data.phone_code}
                       onChange={(e) => {
                           data.invite_code = e.target.value
                       }}/>
            </div>
            <p className={css.hint + ' hintGray'}>(new user registrations by invitation only)</p>
            <div className={css.phoneCodeBox} align='middle'>
                <p className={css.loginDesc + ' wineRed'}>you phone code:</p>
                <Input placeholder='phone code'
                       className={css.phoneCode}
                       defaultValue={data.phone_code}
                       onChange={(e) => {
                           data.phone_code = e.target.value
                       }}/>
                {data.phone_code_time == 0 ?
                    <Button onClick={() => {
                        verify_phone_send_code()
                    }}>send code</Button>
                    : <CountDown
                        style={{color: 'red'}}
                        format={time => {
                            return moment(time).format('mm:ss');
                        }}
                        target={data.phone_code_time}
                        onEnd={() => {
                            data.phone_code_time = 0;
                        }}/>}
            </div>
            <p className={css.voiceCode + ' hintGray'}
               onClick={() => {
                   verify_phone_send_code(true)
               }}>I did NOT get the text code, call my number.</p>
            {
                store.register.is_show_verify_ok ?
                    (
                        <div>
                            <Col align='middle'>
                                <Image className={css.greenRight} src={right_svg} preview={false}/>
                            </Col>
                            <p className={css.loginDesc + ' wineRed'}>Registration with your phone # was
                                successful! </p>
                            <p className={css.loginDesc + ' wineRed'}>What's next?</p>
                            <p>-- Use your phone number to sign in next time. We will text you or call you for a code to
                                sign in.
                                -- Once you log in and complete your interpreter profile, we will conduct an interview,
                                and you can start to take calls once passed the interview.</p>
                            <Button
                                type='primary'
                                className={css.btn}
                                style={{width: '30vw'}}
                                onClick={() => {
                                    history.push('/');
                                }}>OK</Button>
                        </div>
                    ) : (
                        <div>
                            <p>(By clicking Continue, you agree with
                                <span className={css.teamCondition} onClick={() => {
                                    Modal.info({
                                        content: 'Terms and Conditions',
                                    })
                                }}> Terms and Conditions </span>
                                for data privacy)</p>
                            <Button
                                disabled={!data.phone_num || !data.phone_code}
                                onClick={verify_phone}
                                type='primary'
                                className={css.btn}>Continue</Button>
                            <p className={css.pOr}>or</p>
                            <span className={css.loginDesc + ' wineRed'}>Sign in with email:</span>
                            <Button
                                onClick={() => {
                                    history.push('/register/register_email')
                                }}
                                type='primary'
                                className={css.btn}>Continue</Button>
                        </div>
                    )
            }

        </Col>
    );
});

export default RegisterEmail;
