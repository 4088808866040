import React from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Col, Dropdown, Image, Menu, Row} from "antd";
// import './header.module.less';
import headerCss from './header.module.less'
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory, useLocation} from "react-router-dom";

let store = require('src/store');
let history;
let menu;
let commonUtils = require('commonProject/utils/Common');
let api = require('src/api');
let loginHelper = require('src/utils/helper/login_helper');
let config = require('commonProject/config/config');
let params = {};

function init() {
    menu = (
        <Menu>
            <Menu.Item>
                {!store.persist.token ? (
                    <div>
                        <p onClick={() => {
                            window.location.href = `${config.url.company_home_host}`;
                        }}>Company home</p>
                        <p onClick={() => {
                            window.location.href = `${config.url.company_home_host}`;
                        }}>Contact Us</p>
                    </div>
                ) : (
                    <div>
                        <p onClick={() => {
                            history.push('/register_add_info?type=view')
                        }}>Profile and Settings</p>
                        {/*<p onClick={() => {*/}
                        {/*    history.push('/cash_order_list')*/}
                        {/*}}>Cash order list</p>*/}
                        <p onClick={() => {
                            history.push('/order_list')
                        }}>Order list</p>
                        {/*<p onClick={() => {*/}
                        {/*    history.push('/money_cut_of')*/}
                        {/*}}>Money list</p>*/}
                        <p onClick={logout}>Log Out</p>
                    </div>
                )}

            </Menu.Item>
        </Menu>
    );
}

async function logout() {
    loginHelper.logout(history);
}

let Header = observer(function () {
    history = useHistory();

    params = commonUtils.query2ObjParams(useLocation().search);

    if (params.admin_token && params.user_name) {
        loginHelper.login(params,history);
    }
    init();
    return (
        <Row className={headerCss.headerContainBox1}>
            <span className={headerCss.flexGrow1}/>
            <Dropdown overlay={menu} trigger={['click']}>
                <Image src={menuSvg} preview={false} className={headerCss.menuIcon}/>
            </Dropdown>
        </Row>
    )
})

export default Header;
