let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');
let interpreter = {
    login(params) {
        return axios.post('/webapp/interpreter/login', params);
    },
    logout(params) {
        return axios.get('/webapp/interpreter/logout', {params});
    },
    register(params) {
        return axios.post('/webapp/interpreter/register', params);
    },
    reset_password(params) {
        return axios.post('/webapp/interpreter/reset_password', params);
    },
    //更新口译师数据 更新完后要更新本地缓存数据
    async update_interpreter(params) {
        let res = await axios.post('/webapp/interpreter/update_interpreter', params);
        if (res.code == 0) {
            await this.get_interpreter_info();
        }
        return res;
    },
    //获取口译师数据 获取成功后自动保存到缓存里面
    async get_interpreter_info(params) {
        let res = await axios.get('/webapp/interpreter/get_interpreter_info', {params});
        if (res.code == 0) {
            store.persist.userInfo = res.data;
        }
        return res;
    },
    //获取口译师未来工作时间
    get_interpreter_work_time(params) {
        return axios.get('/webapp/interpreter/get_interpreter_work_time', {params});
    },
    //添加口译师未来工作时间
    add_interpreter_work_time(params) {
        return axios.post('/webapp/interpreter/add_interpreter_work_time', params);
    },
    //删除口译师未来工作时间
    del_interpreter_work_time(params) {
        return axios.post('/webapp/interpreter/del_interpreter_work_time', params);
    },
    //更新口译师未来工作时间
    update_interpreter_work_time(params) {
        return axios.post('/webapp/interpreter/update_interpreter_work_time', params);
    },
    //获取主页数据
    get_home_info(params) {
        return axios.get('/webapp/interpreter/get_home_info', {params});
    },
    verify_phone_send_code(params) {
        params.role_type='interpreter';
        return axios.post('/webapp/interpreter/verify_phone_send_code', params);
    },
    verify_email_send_code(params) {
        params.role_type='interpreter';
        return axios.post('/webapp/interpreter/verify_email_send_code', params);
    },
    verify_email(params) {
        return axios.post('/webapp/interpreter/verify_email', params);
    },
    verify_phone(params) {
        return axios.post('/webapp/interpreter/verify_phone', params);
    },
    updateTodayAwaitTime(params) {
        return axios.post('/webapp/interpreter/updateTodayAwaitTime', params);
    },
}

module.exports = interpreter;
