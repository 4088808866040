import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Button, Card, Col, DatePicker, Divider, Image, InputNumber, List, message, Radio, Row} from 'antd';
import {useHistory} from "react-router-dom";

let store = require('src/store');
let formData;
let data = store.add_order;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
let api = require('src/api');
let moment = require('moment');
let history;

function submit() {
    let formData = store.persist.add_order.formData;
    if (!formData.subscribe_time || !formData.subscribe_time_length) return message.error('you should choose time');
    history.push('/add_order/book_confirm');
}

async function getUsableTime() {
    if (!formData.subscribe_time || !formData.subscribe_time_length) return message.error('subscribe_time and time length not be empty')
    let params = {
        subscribe_time: moment(formData.subscribe_time).format('yyyy-MM-DD'),
        subscribe_time_length: formData.subscribe_time_length,
        times: data.request_times
    };
    let result = await api.order.get_interpret_usable_time(params);
    if (result.code != 0) return;
    data.usable_times = result.data;
    data.request_times += 1;
}

// create observer
let PrebookingTime = observer(function () {
    history = useHistory();
    formData = store.persist.add_order.formData;

    useEffect(() => {
    }, [])

    let prebookingTimeData = store.add_order.prebooking_time;
    return (
        <Col>
            <p>step 4 of 4:Pre-booking</p>
            <p>{`You chose:${formData.chooseLang.language_name}-${formData.support_service_type.type_name}`}</p>
            <Divider/>
            <p>Select the Service Date:</p>
            <DatePicker defaultValue={moment(formData.subscribe_time) ?? moment()}
                        disabledDate={commonUtils.disabledDate}
                        suffixIcon={null}
                        onChange={date => {
                            formData.subscribe_time = date;
                            data.usable_times = [];
                            message.success('choose time:' + moment(date).format('yyyy-MM-DD'))
                        }}/>
            <Divider/>
            <p>Try to find an available time slot:</p>
            <Row>
                <span>Estimate the duration:</span>
                <InputNumber size='small' min={0} max={24} defaultValue={0} onChange={value => {
                    prebookingTimeData.hour = value
                }}/>
                <span>hr,</span>
                <InputNumber size='small' min={0} max={60} defaultValue={0} onChange={value => {
                    prebookingTimeData.minute = value
                }}/>
                <span>min</span>
            </Row>
            <p></p>
            <Col offset={10}>
                <Button onClick={() => {
                    formData.subscribe_time_length = prebookingTimeData.hour * 60 + prebookingTimeData.minute;
                    getUsableTime();
                    // history.push('')
                }}>confirm</Button>
            </Col>
            <Radio.Group onChange={e => {
                let time = moment(e.target.value.startTime, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm:ss');
                formData.subscribe_time = time;
                message.success(time);
            }}>
                {
                    store.add_order.usable_times.map(item => {
                        return (
                            <Radio value={item}
                                   style={{'display': 'block'}}>
                                {moment(item.startTime).format('HH:mm') + '~' + moment(item.endTime).format('HH:mm')}
                            </Radio>
                        )
                    })
                }
            </Radio.Group>
            <p></p>
            <Button onClick={submit}>continue</Button>
        </Col>
    );
})

export default PrebookingTime;